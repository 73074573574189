.hubspot-form {
  padding-bottom: 60px;
}

.hubspot-form input[type="text"],
.hubspot-form input[type="email"],
.form-group input[type="text"],
.form-group input[type="email"] {
  @extend .form-control, .form-control-lg;
  margin-bottom: 8px;
}

.hubspot-form .hs-interests .hs-form-required {
  display: none;
}

.hubspot-form ul[role="checkbox"], .form-group ul[role="checkbox"] {
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
  @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.hubspot-form label, .form-group label {
  color: #333738;
  font-weight: 400;
  display: table;
  margin-bottom: 40px;
  @media screen and (min-width: map-get($grid-breakpoints, "md")) {
    margin-bottom: 10px
  }
  @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    flex: 50%;
    &:nth-of-type(1) {
      margin-right: 8px;
    }
    &:nth-of-type(2) {
      margin-right: 8px;
      margin-left: 8px;
    }
    &:nth-of-type(3) {
      margin-right: 8px;
      margin-left: 8px;
    }
    &:nth-of-type(4) {
      margin-left: 8px;
    }
  }
}

.hubspot-form label input[type="checkbox"],
.form-group label input[type="checkbox"],
.hubspot-form label span,
.form-group label span {
  display: table-cell;
}

.hubspot-form label span,
.form-group label span {
  padding-left: 5px;
}

.hubspot-form input[type="checkbox"],
.form-group input[type="checkbox"]  {
  height: 13px;
  min-width: 13px;
  padding: 10px 16px;
  width: 100%;
  color: #797d82;
}

.hubspot-form-submit {
  @extend .btn, .btn-primary, .btn-lg;
  margin-top: 30px;
}

.hubspot-form-submit:hover,
.hubspot-form-submit:focus {
  background-color: #ffffff;
  border-color: #133965;
  color: #17457a;
}

.hubspot-form .hs-error-msg {
  color: #CD163F;
  margin-bottom: 15px; 
}

.submitted-message {
  padding-bottom: 60px;
}