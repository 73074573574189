@charset "UTF-8";
/*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 *
 * CHANGELOG
 * CRAFT B5 - Upgrade bootstrap 3.3.2 to 5.0.2
 * CRAFT B5 - 09/20/21 - Include custom override styles 
 */
/*
 * CHANGELOGS 
 * CRAFT B5 - Chnage bootstrap default color to match previous color scheme 
 */
/*CRAFT B5*/
/*-CRAFT B5*/
/*CRAFT B5*/
/*
 * CHANGELOGS
 * CRAFT B5 - Upgrade bootstrap 3.3.2 - 5.0.2
 * Bootstrap has upgraded the color notations and hence some of the bootstrap 3 variables are not available. 
 * Not using the new bootstrap values as the colors, spacing, etc would be off if we use the new values and not match the original site design
 * CRAFT B5 - Define custom font sizes based on rem values, instead of px 
 */
@import url("https://use.typekit.net/oog5rbx.css");
.img-responsive, .card-item--list img, .cd-logo img:first-child + img, .cd-logo img {
  max-width: 100%;
  height: auto;
}

.img-centered, .img-banner img, .mobile-carousel .carousel-inner > .item > img, #RachidRules .carousel-inner > .carousel-item > img, #RachidRules .carousel-inner > .carousel-item > picture > img {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  width: auto;
  max-width: none;
  left: 50%;
  transform: translateX(-50%);
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin-top: 10px;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gradient-light {
  background-image: linear-gradient(center top, #eeeeee, #cccccc);
}

.gradient-dark {
  background-image: linear-gradient(center top, #333333, #222222);
}

.row-same-height {
  display: table;
  width: 100%;
}

.col-xs-height {
  display: table-cell;
  float: none !important;
  vertical-align: top;
}

@media (min-width: 768px) {
  .col-md-height {
    display: table-cell;
    float: none !important;
    vertical-align: top;
  }
}
@media (min-width: 992px) {
  .col-lg-height {
    display: table-cell;
    float: none !important;
    vertical-align: top;
  }
}
@media (min-width: 1200px) {
  .col-xl-height {
    display: table-cell;
    float: none !important;
    vertical-align: top;
  }
}
/*CRAFT B5*/
/*HI-001*/
.color-white {
  color: #ffffff !important;
}

.color-gray-darker {
  color: #000000 !important;
}

.color-gray-dark {
  color: #333738 !important;
}

.color-gray {
  color: #797D82 !important;
}

.color-gray-light {
  color: #C7C9C8 !important;
}

.color-gray-lighter {
  color: #E9EBEA !important;
}

.color-brand-primary {
  color: #17457A !important;
}

.color-brand-secondary {
  color: #CD163F !important;
}

.bg-gray-darker {
  background-color: #000000 !important;
}

.bg-gray-dark {
  background-color: #333738 !important;
}

.bg-gray {
  background-color: #797D82 !important;
}

.bg-gray-light {
  background-color: #C7C9C8 !important;
}

.bg-gray-lighter {
  background-color: #E9EBEA !important;
}

.bg-brand-primary {
  background-color: #17457A !important;
}

.bg-brand-secondary {
  background-color: #CD163F !important;
}

/*CRAFT B5*/
/*-CRAFT B5*/
/*CRAFT B5*/
/*CRAFT B5*/
/*CRAFT B5*/
/*-CRAFT B5*/
/*CRAFT B5*/
/*-CRAFT B5*/
/*-CRAFT B5*/
:root {
  --bs-blue: #17457a;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #17457a;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-font-sans-serif: urw-din, Helvetica, Arial, sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .accordion-item > h4.accordion-item-toggle, .accordion-item > .accordion-item-toggle.h4, .accordion-item h4.accordion-header button, .accordion-item .accordion-header.h4 button, .accordion-item > .accordion-item-content h5.accordion-header button, .accordion-item > .accordion-item-content .accordion-header.h5 button, .accordion-item .accordion-collapse h5.accordion-header button, .accordion-item .accordion-collapse .accordion-header.h5 button, .accordion-item .accordion-collapse h5, .accordion-item .accordion-collapse .h5, .accordion-item > .accordion-item-content h5, .accordion-item > .accordion-item-content .h5, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .accordion-item > h4.accordion-item-toggle, .accordion-item > .accordion-item-toggle.h4, .accordion-item h4.accordion-header button, .accordion-item .accordion-header.h4 button, .accordion-item > .accordion-item-content h5.accordion-header button, .accordion-item > .accordion-item-content .accordion-header.h5 button, .accordion-item .accordion-collapse h5.accordion-header button, .accordion-item .accordion-collapse .accordion-header.h5 button, .accordion-item .accordion-collapse h5, .accordion-item .accordion-collapse .h5, .accordion-item > .accordion-item-content h5, .accordion-item > .accordion-item-content .h5, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .accordion-item > h4.accordion-item-toggle, .accordion-item > .accordion-item-toggle.h4, .accordion-item h4.accordion-header button, .accordion-item .accordion-header.h4 button, .accordion-item > .accordion-item-content h5.accordion-header button, .accordion-item > .accordion-item-content .accordion-header.h5 button, .accordion-item .accordion-collapse h5.accordion-header button, .accordion-item .accordion-collapse .accordion-header.h5 button, .accordion-item .accordion-collapse h5, .accordion-item .accordion-collapse .h5, .accordion-item > .accordion-item-content h5, .accordion-item > .accordion-item-content .h5, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #17457a;
  text-decoration: underline;
}
a:hover {
  color: #123762;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #d1dae4;
  --bs-table-striped-bg: #c7cfd9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcc4cd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1cad3;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcc4cd;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

/*CHANGELOG
 * CRAFT B5 - add hubspot classes to style
 */
.form-control, .hubspot-form input[type=text],
.hubspot-form input[type=email],
.form-group input[type=text],
.form-group input[type=email] {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control, .hubspot-form input[type=text],
.hubspot-form input[type=email],
.form-group input[type=text],
.form-group input[type=email] {
    transition: none;
  }
}
.form-control[type=file], .hubspot-form input[type=file][type=text],
.hubspot-form input[type=file][type=email],
.form-group input[type=file][type=text],
.form-group input[type=file][type=email] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]), .hubspot-form input[type=file][type=text]:not(:disabled):not([readonly]),
.hubspot-form input[type=file][type=email]:not(:disabled):not([readonly]),
.form-group input[type=file][type=text]:not(:disabled):not([readonly]),
.form-group input[type=file][type=email]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus, .hubspot-form input[type=text]:focus,
.hubspot-form input[type=email]:focus,
.form-group input[type=text]:focus,
.form-group input[type=email]:focus {
  color: #212529;
  background-color: #fff;
  border-color: #8ba2bd;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(23, 69, 122, 0.25);
}
.form-control::-webkit-date-and-time-value, .hubspot-form input[type=text]::-webkit-date-and-time-value,
.hubspot-form input[type=email]::-webkit-date-and-time-value,
.form-group input[type=text]::-webkit-date-and-time-value,
.form-group input[type=email]::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-moz-placeholder, .hubspot-form input[type=text]::-moz-placeholder, .hubspot-form input[type=email]::-moz-placeholder, .form-group input[type=text]::-moz-placeholder, .form-group input[type=email]::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder, .hubspot-form input[type=text]::placeholder,
.hubspot-form input[type=email]::placeholder,
.form-group input[type=text]::placeholder,
.form-group input[type=email]::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .hubspot-form input[type=text]:disabled,
.hubspot-form input[type=email]:disabled,
.form-group input[type=text]:disabled,
.form-group input[type=email]:disabled, .form-control[readonly], .hubspot-form input[readonly][type=text],
.hubspot-form input[readonly][type=email],
.form-group input[readonly][type=text],
.form-group input[readonly][type=email] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::-webkit-file-upload-button, .hubspot-form input[type=text]::-webkit-file-upload-button, .hubspot-form input[type=email]::-webkit-file-upload-button, .form-group input[type=text]::-webkit-file-upload-button, .form-group input[type=email]::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control::file-selector-button, .hubspot-form input[type=text]::file-selector-button,
.hubspot-form input[type=email]::file-selector-button,
.form-group input[type=text]::file-selector-button,
.form-group input[type=email]::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button, .hubspot-form input[type=text]::-webkit-file-upload-button, .hubspot-form input[type=email]::-webkit-file-upload-button, .form-group input[type=text]::-webkit-file-upload-button, .form-group input[type=email]::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
  .form-control::file-selector-button, .hubspot-form input[type=text]::file-selector-button,
.hubspot-form input[type=email]::file-selector-button,
.form-group input[type=text]::file-selector-button,
.form-group input[type=email]::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button, .hubspot-form input[type=text]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button, .hubspot-form input[type=email]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button, .form-group input[type=text]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button, .form-group input[type=email]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button, .hubspot-form input[type=text]:hover:not(:disabled):not([readonly])::file-selector-button,
.hubspot-form input[type=email]:hover:not(:disabled):not([readonly])::file-selector-button,
.form-group input[type=text]:hover:not(:disabled):not([readonly])::file-selector-button,
.form-group input[type=email]:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button, .hubspot-form input[type=text]::-webkit-file-upload-button,
.hubspot-form input[type=email]::-webkit-file-upload-button,
.form-group input[type=text]::-webkit-file-upload-button,
.form-group input[type=email]::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button, .hubspot-form input[type=text]::-webkit-file-upload-button,
.hubspot-form input[type=email]::-webkit-file-upload-button,
.form-group input[type=text]::-webkit-file-upload-button,
.form-group input[type=email]::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button, .hubspot-form input[type=text]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.hubspot-form input[type=email]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.form-group input[type=text]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.form-group input[type=email]:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg, .hubspot-form input.form-control-plaintext[type=text],
.hubspot-form input.form-control-plaintext[type=email],
.form-group input.form-control-plaintext[type=text],
.form-group input.form-control-plaintext[type=email] {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg,
.hubspot-form input[type=text], .form-group input[type=text],
.hubspot-form input[type=email], .form-group input[type=email] {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.form-control-lg::-webkit-file-upload-button, .hubspot-form input[type=text]::-webkit-file-upload-button, .form-group input[type=text]::-webkit-file-upload-button, .hubspot-form input[type=email]::-webkit-file-upload-button, .form-group input[type=email]::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::file-selector-button,
.hubspot-form input[type=text]::file-selector-button, .form-group input[type=text]::file-selector-button,
.hubspot-form input[type=email]::file-selector-button, .form-group input[type=email]::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button,
.hubspot-form input[type=text]::-webkit-file-upload-button, .form-group input[type=text]::-webkit-file-upload-button,
.hubspot-form input[type=email]::-webkit-file-upload-button, .form-group input[type=email]::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

/*CRAFT B5*/
.hubspot-form input[type=text], .form-group input[type=text],
.hubspot-form input[type=email], .form-group input[type=email] {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 0;
}

/*-CRAFT B5*/
textarea.form-control {
  min-height: calc(1.5em + (0.75rem + 2px));
}
textarea.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
}
textarea.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #8ba2bd;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(23, 69, 122, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #8ba2bd;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(23, 69, 122, 0.25);
}
.form-check-input:checked {
  background-color: #17457a;
  border-color: #17457a;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #17457a;
  border-color: #17457a;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238ba2bd'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check[disabled] + .hubspot-form-submit, .btn-check:disabled + .btn, .btn-check:disabled + .hubspot-form-submit {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(23, 69, 122, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(23, 69, 122, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #17457a;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b9c7d7;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #17457a;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b9c7d7;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control, .hubspot-form .form-floating > input[type=text],
.hubspot-form .form-floating > input[type=email],
.form-group .form-floating > input[type=text],
.form-group .form-floating > input[type=email],
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control, .hubspot-form .form-floating > input[type=text],
.hubspot-form .form-floating > input[type=email],
.form-group .form-floating > input[type=text],
.form-group .form-floating > input[type=email] {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::-moz-placeholder, .hubspot-form .form-floating > input[type=text]::-moz-placeholder, .hubspot-form .form-floating > input[type=email]::-moz-placeholder, .form-group .form-floating > input[type=text]::-moz-placeholder, .form-group .form-floating > input[type=email]::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder, .hubspot-form .form-floating > input[type=text]::placeholder,
.hubspot-form .form-floating > input[type=email]::placeholder,
.form-group .form-floating > input[type=text]::placeholder,
.form-group .form-floating > input[type=email]::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown), .hubspot-form .form-floating > input[type=text]:not(:-moz-placeholder-shown), .hubspot-form .form-floating > input[type=email]:not(:-moz-placeholder-shown), .form-group .form-floating > input[type=text]:not(:-moz-placeholder-shown), .form-group .form-floating > input[type=email]:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .hubspot-form .form-floating > input[type=text]:focus,
.hubspot-form .form-floating > input[type=email]:focus,
.form-group .form-floating > input[type=text]:focus,
.form-group .form-floating > input[type=email]:focus, .form-floating > .form-control:not(:placeholder-shown), .hubspot-form .form-floating > input[type=text]:not(:placeholder-shown),
.hubspot-form .form-floating > input[type=email]:not(:placeholder-shown),
.form-group .form-floating > input[type=text]:not(:placeholder-shown),
.form-group .form-floating > input[type=email]:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill, .hubspot-form .form-floating > input[type=text]:-webkit-autofill,
.hubspot-form .form-floating > input[type=email]:-webkit-autofill,
.form-group .form-floating > input[type=text]:-webkit-autofill,
.form-group .form-floating > input[type=email]:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label, .hubspot-form .form-floating > input[type=text]:not(:-moz-placeholder-shown) ~ label, .hubspot-form .form-floating > input[type=email]:not(:-moz-placeholder-shown) ~ label, .form-group .form-floating > input[type=text]:not(:-moz-placeholder-shown) ~ label, .form-group .form-floating > input[type=email]:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label, .hubspot-form .form-floating > input[type=text]:focus ~ label,
.hubspot-form .form-floating > input[type=email]:focus ~ label,
.form-group .form-floating > input[type=text]:focus ~ label,
.form-group .form-floating > input[type=email]:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.hubspot-form .form-floating > input[type=text]:not(:placeholder-shown) ~ label,
.hubspot-form .form-floating > input[type=email]:not(:placeholder-shown) ~ label,
.form-group .form-floating > input[type=text]:not(:placeholder-shown) ~ label,
.form-group .form-floating > input[type=email]:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label, .hubspot-form .form-floating > input[type=text]:-webkit-autofill ~ label,
.hubspot-form .form-floating > input[type=email]:-webkit-autofill ~ label,
.form-group .form-floating > input[type=text]:-webkit-autofill ~ label,
.form-group .form-floating > input[type=email]:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control, .hubspot-form .input-group > input[type=text],
.hubspot-form .input-group > input[type=email],
.form-group .input-group > input[type=text],
.form-group .input-group > input[type=email],
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus, .hubspot-form .input-group > input[type=text]:focus,
.hubspot-form .input-group > input[type=email]:focus,
.form-group .input-group > input[type=text]:focus,
.form-group .input-group > input[type=email]:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn, .input-group .hubspot-form-submit {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus, .input-group .hubspot-form-submit:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control, .hubspot-form .input-group-lg > input[type=text],
.hubspot-form .input-group-lg > input[type=email],
.form-group .input-group-lg > input[type=text],
.form-group .input-group-lg > input[type=email],
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.input-group-lg > .hubspot-form-submit {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control, .hubspot-form .input-group-sm > input[type=text],
.hubspot-form .input-group-sm > input[type=email],
.form-group .input-group-sm > input[type=text],
.form-group .input-group-sm > input[type=email],
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.input-group-sm > .hubspot-form-submit {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .was-validated .hubspot-form input[type=text]:valid, .hubspot-form .was-validated input[type=text]:valid,
.was-validated .hubspot-form input[type=email]:valid,
.hubspot-form .was-validated input[type=email]:valid,
.was-validated .form-group input[type=text]:valid,
.form-group .was-validated input[type=text]:valid,
.was-validated .form-group input[type=email]:valid,
.form-group .was-validated input[type=email]:valid, .form-control.is-valid, .hubspot-form input.is-valid[type=text],
.hubspot-form input.is-valid[type=email],
.form-group input.is-valid[type=text],
.form-group input.is-valid[type=email] {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .was-validated .hubspot-form input[type=text]:valid:focus, .hubspot-form .was-validated input[type=text]:valid:focus,
.was-validated .hubspot-form input[type=email]:valid:focus,
.hubspot-form .was-validated input[type=email]:valid:focus,
.was-validated .form-group input[type=text]:valid:focus,
.form-group .was-validated input[type=text]:valid:focus,
.was-validated .form-group input[type=email]:valid:focus,
.form-group .was-validated input[type=email]:valid:focus, .form-control.is-valid:focus, .hubspot-form input.is-valid[type=text]:focus,
.hubspot-form input.is-valid[type=email]:focus,
.form-group input.is-valid[type=text]:focus,
.form-group input.is-valid[type=email]:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .was-validated .input-group .hubspot-form input[type=text]:valid, .hubspot-form .was-validated .input-group input[type=text]:valid,
.was-validated .input-group .hubspot-form input[type=email]:valid,
.hubspot-form .was-validated .input-group input[type=email]:valid,
.was-validated .input-group .form-group input[type=text]:valid,
.form-group .was-validated .input-group input[type=text]:valid,
.was-validated .input-group .form-group input[type=email]:valid,
.form-group .was-validated .input-group input[type=email]:valid, .input-group .form-control.is-valid, .input-group .hubspot-form input.is-valid[type=text], .hubspot-form .input-group input.is-valid[type=text],
.input-group .hubspot-form input.is-valid[type=email],
.hubspot-form .input-group input.is-valid[type=email],
.input-group .form-group input.is-valid[type=text],
.form-group .input-group input.is-valid[type=text],
.input-group .form-group input.is-valid[type=email],
.form-group .input-group input.is-valid[type=email],
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .was-validated .input-group .hubspot-form input[type=text]:valid:focus, .hubspot-form .was-validated .input-group input[type=text]:valid:focus,
.was-validated .input-group .hubspot-form input[type=email]:valid:focus,
.hubspot-form .was-validated .input-group input[type=email]:valid:focus,
.was-validated .input-group .form-group input[type=text]:valid:focus,
.form-group .was-validated .input-group input[type=text]:valid:focus,
.was-validated .input-group .form-group input[type=email]:valid:focus,
.form-group .was-validated .input-group input[type=email]:valid:focus, .input-group .form-control.is-valid:focus, .input-group .hubspot-form input.is-valid[type=text]:focus, .hubspot-form .input-group input.is-valid[type=text]:focus,
.input-group .hubspot-form input.is-valid[type=email]:focus,
.hubspot-form .input-group input.is-valid[type=email]:focus,
.input-group .form-group input.is-valid[type=text]:focus,
.form-group .input-group input.is-valid[type=text]:focus,
.input-group .form-group input.is-valid[type=email]:focus,
.form-group .input-group input.is-valid[type=email]:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .was-validated .hubspot-form input[type=text]:invalid, .hubspot-form .was-validated input[type=text]:invalid,
.was-validated .hubspot-form input[type=email]:invalid,
.hubspot-form .was-validated input[type=email]:invalid,
.was-validated .form-group input[type=text]:invalid,
.form-group .was-validated input[type=text]:invalid,
.was-validated .form-group input[type=email]:invalid,
.form-group .was-validated input[type=email]:invalid, .form-control.is-invalid, .hubspot-form input.is-invalid[type=text],
.hubspot-form input.is-invalid[type=email],
.form-group input.is-invalid[type=text],
.form-group input.is-invalid[type=email] {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .was-validated .hubspot-form input[type=text]:invalid:focus, .hubspot-form .was-validated input[type=text]:invalid:focus,
.was-validated .hubspot-form input[type=email]:invalid:focus,
.hubspot-form .was-validated input[type=email]:invalid:focus,
.was-validated .form-group input[type=text]:invalid:focus,
.form-group .was-validated input[type=text]:invalid:focus,
.was-validated .form-group input[type=email]:invalid:focus,
.form-group .was-validated input[type=email]:invalid:focus, .form-control.is-invalid:focus, .hubspot-form input.is-invalid[type=text]:focus,
.hubspot-form input.is-invalid[type=email]:focus,
.form-group input.is-invalid[type=text]:focus,
.form-group input.is-invalid[type=email]:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .was-validated .input-group .hubspot-form input[type=text]:invalid, .hubspot-form .was-validated .input-group input[type=text]:invalid,
.was-validated .input-group .hubspot-form input[type=email]:invalid,
.hubspot-form .was-validated .input-group input[type=email]:invalid,
.was-validated .input-group .form-group input[type=text]:invalid,
.form-group .was-validated .input-group input[type=text]:invalid,
.was-validated .input-group .form-group input[type=email]:invalid,
.form-group .was-validated .input-group input[type=email]:invalid, .input-group .form-control.is-invalid, .input-group .hubspot-form input.is-invalid[type=text], .hubspot-form .input-group input.is-invalid[type=text],
.input-group .hubspot-form input.is-invalid[type=email],
.hubspot-form .input-group input.is-invalid[type=email],
.input-group .form-group input.is-invalid[type=text],
.form-group .input-group input.is-invalid[type=text],
.input-group .form-group input.is-invalid[type=email],
.form-group .input-group input.is-invalid[type=email],
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .was-validated .input-group .hubspot-form input[type=text]:invalid:focus, .hubspot-form .was-validated .input-group input[type=text]:invalid:focus,
.was-validated .input-group .hubspot-form input[type=email]:invalid:focus,
.hubspot-form .was-validated .input-group input[type=email]:invalid:focus,
.was-validated .input-group .form-group input[type=text]:invalid:focus,
.form-group .was-validated .input-group input[type=text]:invalid:focus,
.was-validated .input-group .form-group input[type=email]:invalid:focus,
.form-group .was-validated .input-group input[type=email]:invalid:focus, .input-group .form-control.is-invalid:focus, .input-group .hubspot-form input.is-invalid[type=text]:focus, .hubspot-form .input-group input.is-invalid[type=text]:focus,
.input-group .hubspot-form input.is-invalid[type=email]:focus,
.hubspot-form .input-group input.is-invalid[type=email]:focus,
.input-group .form-group input.is-invalid[type=text]:focus,
.form-group .input-group input.is-invalid[type=text]:focus,
.input-group .form-group input.is-invalid[type=email]:focus,
.form-group .input-group input.is-invalid[type=email]:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn, .hubspot-form-submit {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn, .hubspot-form-submit {
    transition: none;
  }
}
.btn:hover, .hubspot-form-submit:hover {
  color: #212529;
}
.btn-check:focus + .btn, .btn-check:focus + .hubspot-form-submit, .btn:focus, .hubspot-form-submit:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(23, 69, 122, 0.25);
}
.btn:disabled, .hubspot-form-submit:disabled, .btn.disabled, .disabled.hubspot-form-submit, fieldset:disabled .btn, fieldset:disabled .hubspot-form-submit {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary, .hubspot-form-submit {
  color: #fff;
  background-color: #17457a;
  border-color: #17457a;
}
.btn-primary:hover, .hubspot-form-submit:hover {
  color: #fff;
  background-color: #143b68;
  border-color: #123762;
}
.btn-check:focus + .btn-primary, .btn-check:focus + .hubspot-form-submit, .btn-primary:focus, .hubspot-form-submit:focus {
  color: #fff;
  background-color: #143b68;
  border-color: #123762;
  box-shadow: 0 0 0 0.25rem rgba(58, 97, 142, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:checked + .hubspot-form-submit, .btn-check:active + .btn-primary, .btn-check:active + .hubspot-form-submit, .btn-primary:active, .hubspot-form-submit:active, .btn-primary.active, .active.hubspot-form-submit, .show > .btn-primary.dropdown-toggle, .show > .dropdown-toggle.hubspot-form-submit {
  color: #fff;
  background-color: #123762;
  border-color: #11345c;
}
.btn-check:checked + .btn-primary:focus, .btn-check:checked + .hubspot-form-submit:focus, .btn-check:active + .btn-primary:focus, .btn-check:active + .hubspot-form-submit:focus, .btn-primary:active:focus, .hubspot-form-submit:active:focus, .btn-primary.active:focus, .active.hubspot-form-submit:focus, .show > .btn-primary.dropdown-toggle:focus, .show > .dropdown-toggle.hubspot-form-submit:focus {
  box-shadow: 0 0 0 0.25rem rgba(58, 97, 142, 0.5);
}
.btn-primary:disabled, .hubspot-form-submit:disabled, .btn-primary.disabled, .disabled.hubspot-form-submit {
  color: #fff;
  background-color: #17457a;
  border-color: #17457a;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-primary {
  color: #17457a;
  border-color: #17457a;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #17457a;
  border-color: #17457a;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(23, 69, 122, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #17457a;
  border-color: #17457a;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(23, 69, 122, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #17457a;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #212529;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #17457a;
  text-decoration: underline;
}
.btn-link:hover {
  color: #123762;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .hubspot-form-submit, .btn-group-lg > .btn, .btn-group-lg > .hubspot-form-submit {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .hubspot-form-submit {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #17457a;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #17457a;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn, .btn-group > .hubspot-form-submit,
.btn-group-vertical > .btn,
.btn-group-vertical > .hubspot-form-submit {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:checked + .hubspot-form-submit,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn-check:focus + .hubspot-form-submit,
.btn-group > .btn:hover,
.btn-group > .hubspot-form-submit:hover,
.btn-group > .btn:focus,
.btn-group > .hubspot-form-submit:focus,
.btn-group > .btn:active,
.btn-group > .hubspot-form-submit:active,
.btn-group > .btn.active,
.btn-group > .active.hubspot-form-submit,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:checked + .hubspot-form-submit,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn-check:focus + .hubspot-form-submit,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .hubspot-form-submit:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .hubspot-form-submit:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .hubspot-form-submit:active,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .active.hubspot-form-submit {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child), .btn-group > .hubspot-form-submit:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .hubspot-form-submit:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .hubspot-form-submit {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3), .btn-group > .hubspot-form-submit:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > :not(.btn-check) + .hubspot-form-submit,
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .hubspot-form-submit {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .btn-group-sm > .hubspot-form-submit + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .hubspot-form-submit + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .btn-group-lg > .hubspot-form-submit + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn, .btn-group-vertical > .hubspot-form-submit,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .hubspot-form-submit:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .hubspot-form-submit:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn-group:not(:last-child) > .hubspot-form-submit {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .hubspot-form-submit ~ .btn, .btn-group-vertical > .btn ~ .hubspot-form-submit, .btn-group-vertical > .hubspot-form-submit ~ .hubspot-form-submit,
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .hubspot-form-submit {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #17457a;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #123762;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #17457a;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #153e6e;
  background-color: #e8ecf2;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23153e6e'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #8ba2bd;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(23, 69, 122, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #17457a;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #123762;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #123762;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(23, 69, 122, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #17457a;
  border-color: #17457a;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge, .hubspot-form-submit .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #0e2949;
  background-color: #d1dae4;
  border-color: #b9c7d7;
}
.alert-primary .alert-link {
  color: #0b213a;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #17457a;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #17457a;
  border-color: #17457a;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #0e2949;
  background-color: #d1dae4;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #0e2949;
  background-color: #bcc4cd;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #0e2949;
  border-color: #0e2949;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(23, 69, 122, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast:not(.showing):not(.show) {
  opacity: 0;
}
.toast.hide {
  display: none;
}

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 12px;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #CD163F;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #17457a;
}
.link-primary:hover, .link-primary:focus {
  color: #123762;
}

.link-secondary {
  color: #6c757d;
}
.link-secondary:hover, .link-secondary:focus {
  color: #565e64;
}

.link-success {
  color: #198754;
}
.link-success:hover, .link-success:focus {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #212529;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none, .breadcrumb, .cd-logo img:first-child + img {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #17457a !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4, .card {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #17457a !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-success {
  color: #198754 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-light {
  color: #f8f9fa !important;
}

.text-dark {
  color: #212529 !important;
}

.text-white {
  color: #ffffff !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #17457a !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #198754 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-dark {
  background-color: #212529 !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex, .breadcrumb {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block, .cd-logo img:first-child + img {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none, .nav-pills, .jumbotron, .footer-2, .footer-1, .related-links .other-resources,
.related-links .related-topics, .cd-nav {
    display: none !important;
  }
}
/*-CRAFT B5-*/
/*CRAFT B5*/
/*
 * CHANGELOG 
 * CRAFT B5 - 09/20/21 - File created. Importing custom styles defined in bootstrap 3
 * CRAFT B5 - 04/18/22 - Changing page-link behaviour by removing class and directly targeting anchor link 
 */
html {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
.accordion-item > h4.accordion-item-toggle,
.accordion-item > .accordion-item-toggle.h4,
.accordion-item h4.accordion-header button,
.accordion-item .accordion-header.h4 button,
.accordion-item > .accordion-item-content h5.accordion-header button,
.accordion-item > .accordion-item-content .accordion-header.h5 button,
.accordion-item .accordion-collapse h5.accordion-header button,
.accordion-item .accordion-collapse .accordion-header.h5 button,
.accordion-item .accordion-collapse h5,
.accordion-item .accordion-collapse .h5,
.accordion-item > .accordion-item-content h5,
.accordion-item > .accordion-item-content .h5,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 1.1;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #e9ebea;
  opacity: 1;
}

.lead {
  font-size: 16px;
}
@media (min-width: 768px) {
  .lead {
    font-size: 21px;
  }
}

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #e9ebea;
}

@media screen and (max-width: 768px) {
  .page-wrapper-inner .container {
    padding: 0 20px;
  }
}

small,
.small {
  font-size: 85%;
}

.page-item a {
  padding: 0.875rem 1.375rem;
  position: relative;
  display: block;
  color: #17457a;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.page-item a:hover {
  z-index: 2;
  color: #123762;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-item a:focus {
  z-index: 3;
  color: #123762;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(23, 69, 122, 0.25);
}
.page-item:not(:first-child) a {
  margin-left: -1px;
}
.page-item.active a {
  z-index: 3;
  color: #fff;
  background-color: #17457a;
  border-color: #17457a;
}
.page-item.active a:hover {
  color: #fff;
}

.hidden {
  display: none;
  visibility: hidden;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: #ccc;
}

blockquote {
  border-left: 5px solid #e9ebea;
  padding: 10px 20px;
}

table.dataTable thead tr .sorting::after {
  content: "\f0dc";
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table.dataTable thead tr .sorting_desc:after {
  content: "\f884";
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table.dataTable thead tr .sorting_asc:after {
  content: "\f160";
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-left-color: #CD163F;
}

/*-CRAFT B5*/
/* --------------------------------

Primary style

-------------------------------- */
*::after, *::before {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.8em;
  font-family: urw-din, Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #333738;
  background: #f9f9f9;
}
body.overflow-hidden {
  height: 100vh;
}

h1, h2, .h1, .h2 {
  font-family: urw-din, Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #17457A;
}
h1 a, h2 a, .h1 a, .h2 a {
  text-decoration: none;
}

h1, .h1 {
  font-size: 3em;
  margin-bottom: 30px;
}

h2, .h2 {
  font-size: 2em;
}

h3, .h3 {
  font-size: 24px;
}

h4, .accordion-item > h4.accordion-item-toggle, .accordion-item > .accordion-item-toggle.h4, .accordion-item h4.accordion-header button, .accordion-item .accordion-header.h4 button, .accordion-item > .accordion-item-content h5.accordion-header button, .accordion-item > .accordion-item-content .accordion-header.h5 button, .accordion-item .accordion-collapse h5.accordion-header button, .accordion-item .accordion-collapse .accordion-header.h5 button, .accordion-item .accordion-collapse h5, .accordion-item .accordion-collapse .h5, .accordion-item > .accordion-item-content h5, .accordion-item > .accordion-item-content .h5, .h4 {
  font-size: 20px;
  margin-bottom: 20px;
}

h5, .h5 {
  font-size: 14px;
}

h6, .h6 {
  font-size: 12px;
}

h3, .h3, h4, .accordion-item > h4.accordion-item-toggle, .accordion-item > .accordion-item-toggle.h4, .accordion-item h4.accordion-header button, .accordion-item .accordion-header.h4 button, .accordion-item > .accordion-item-content h5.accordion-header button, .accordion-item > .accordion-item-content .accordion-header.h5 button, .accordion-item .accordion-collapse h5.accordion-header button, .accordion-item .accordion-collapse .accordion-header.h5 button, .accordion-item .accordion-collapse h5, .accordion-item .accordion-collapse .h5, .accordion-item > .accordion-item-content h5, .accordion-item > .accordion-item-content .h5, .h4, h5, .h5, h6, .h6 {
  font-family: urw-din, Helvetica, Arial, sans-serif;
  color: #17457A;
  font-weight: 700;
  font-style: normal;
}
h3 a, .h3 a, h4 a, .accordion-item > h4.accordion-item-toggle a, .accordion-item > .accordion-item-toggle.h4 a, .accordion-item h4.accordion-header button a, .accordion-item .accordion-header.h4 button a, .accordion-item .accordion-collapse h5 a, .accordion-item .accordion-collapse .h5 a, .accordion-item > .accordion-item-content h5 a, .accordion-item > .accordion-item-content .h5 a, .h4 a, h5 a, .h5 a, h6 a, .h6 a {
  text-decoration: none;
}

h1 a, .h1 a, h1 a:hover, h2 a, .h2 a, h2 a:hover, h3 a, .h3 a, h3 a:hover, h4 a, .accordion-item > h4.accordion-item-toggle a, .accordion-item > .accordion-item-toggle.h4 a, .accordion-item h4.accordion-header button a, .accordion-item .accordion-header.h4 button a, .accordion-item .accordion-collapse h5 a, .accordion-item .accordion-collapse .h5 a, .accordion-item > .accordion-item-content h5 a, .accordion-item > .accordion-item-content .h5 a, .h4 a, h4 a:hover, h5 a, .h5 a, h5 a:hover, h6 a, .h6 a, h6 a:hover {
  color: #17457A;
}

table {
  font-family: urw-din, Helvetica, Arial, sans-serif;
}
table th {
  font-family: urw-din, Helvetica, Arial, sans-serif;
  font-weight: 700;
}

.well {
  font-family: urw-din, Helvetica, Arial, sans-serif;
}

.btn, .hubspot-form-submit, input, select, textarea {
  font-family: urw-din, Helvetica, Arial, sans-serif;
}

@media screen and (min-width: 768px) {
  .col-md-header {
    margin-top: 0;
    padding-top: 0;
  }
}

a {
  color: #2b7bb9;
  text-decoration: underline;
}
a.btn, a.hubspot-form-submit {
  text-decoration: none;
}

.small a {
  text-decoration: none;
}

.text-left {
  text-align: left !important;
}

.page-wrapper-inner a {
  font-weight: bold;
}
.page-wrapper-inner ul ul, .page-wrapper-inner ol ul {
  list-style-type: none;
}
.page-wrapper-inner ul ul li:before, .page-wrapper-inner ol ul li:before {
  content: "—";
  position: relative;
  left: -5px;
}
.page-wrapper-inner ul ul li, .page-wrapper-inner ol ul li {
  text-indent: -18px;
}
.page-wrapper-inner ul ul ul, .page-wrapper-inner ol ul ul {
  list-style-type: square;
}
.page-wrapper-inner ul ul ul li:before, .page-wrapper-inner ol ul ul li:before {
  content: normal;
  position: relative;
  left: auto;
}
.page-wrapper-inner ul ul ul li, .page-wrapper-inner ol ul ul li {
  text-indent: 0;
}

.fa-asterisk.form-control-feedback {
  color: #999;
}

.field-validation-error {
  font-size: 0.8em;
  font-style: italic;
}

.form-group {
  position: relative;
}

.form-group .form-control-feedback {
  position: absolute;
  right: 25px;
}

.form-group .form-control-feedback {
  top: 37%;
  font-size: 14px;
}

.form-group label:not(.sr-only) ~ .form-control-feedback {
  top: 65%;
}
@media screen and (max-width: 768px) {
  .form-group label:not(.sr-only) ~ .form-control-feedback {
    top: 73%;
  }
}

.form-group .text-small {
  display: block;
  margin-bottom: 35px;
}
.form-group .text-small em {
  position: absolute;
}

.form-group .fa {
  font-size: 14px;
}

/*
 * CHANGELOGS
 * CRAFT B5 = Change default button font size from $font-size-lg to $font-size-xxl(custom defined)
 * CRAFT B5 - Add missing button properties excluded in the new bootstrap version and fix button-variant parameter ordering issue
 */
.btn, .hubspot-form-submit {
  font-family: urw-din, Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 17px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.42857143;
  padding-right: 30px;
  padding-left: 30px;
}

.btn-default {
  border: 2px solid #17457A;
  /*CRAFT B5*/
  color: #17457a;
  background-color: transparent;
  /*-CRAFT B5*/
}
.btn-default:hover, .btn-default:focus, .btn-default.focus {
  background-color: #17457A;
  color: white;
}

.btn-reverse {
  border-width: 2px;
  /*CRAFT B5*/
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff;
  /*-CRAFT B5*/
}
.btn-reverse:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.15);
  border-color: #cccccc;
}
.btn-check:focus + .btn-reverse, .btn-reverse:focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.15);
  border-color: #cccccc;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-reverse, .btn-check:active + .btn-reverse, .btn-reverse:active, .btn-reverse.active, .show > .btn-reverse.dropdown-toggle {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: #bfbfbf;
}
.btn-check:checked + .btn-reverse:focus, .btn-check:active + .btn-reverse:focus, .btn-reverse:active:focus, .btn-reverse.active:focus, .show > .btn-reverse.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-reverse:disabled, .btn-reverse.disabled {
  color: #fff;
  background-color: transparent;
  border-color: #ffffff;
}

.btn-reverse:hover, .btn-reverse:focus, .btn-reverse.focus {
  background-color: white;
  color: #17457A;
}

.btn-primary, .hubspot-form-submit {
  border: 2px solid transparent;
}
.btn-primary:hover, .hubspot-form-submit:hover, .btn-primary:focus, .hubspot-form-submit:focus, .btn-primary.focus, .focus.hubspot-form-submit {
  border: 2px solid #17457A;
  background-color: white;
  color: #17457A;
}

.btn-lg, .hubspot-form-submit, .btn-group-lg > .btn, .btn-group-lg > .hubspot-form-submit {
  padding: 10px 16px;
  line-height: 1.3333333;
  border-radius: 23px;
  font-size: 18px;
  padding-right: 50px;
  padding-left: 50px;
}

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .hubspot-form-submit {
  padding: 5px 10px;
  font-size: 0.875rem;
  border-radius: 15px;
}

/* ----------------------------------- */
/* ----------------------------------- */
/* ----------------------------------- */
/*           Mega Menu, Codyhouse      */
/* ----------------------------------- */
/* ----------------------------------- */
/* ----------------------------------- */
.cd-main-header {
  font-family: urw-din, Helvetica, Arial, sans-serif;
  font-size: 16px;
}
@media screen and (max-width: 991px) {
  .cd-main-header {
    overflow: hidden;
  }
}
.cd-main-header ol, .cd-main-header ul {
  list-style-type: none;
}

.page-wrapper {
  position: relative;
  z-index: 2;
  background-color: #ffffff;
}
.page-wrapper .page-wrapper-inner {
  padding-bottom: 30px;
}
@media screen and (min-width: 992px) {
  .page-wrapper .page-wrapper-inner {
    padding-bottom: 60px;
  }
}

.page-wrapper, .cd-main-header {
  position: relative;
  transform: translateZ(0);
  /* Changed here */
  transition: transform 0.3s ease-out;
  will-change: transform;
  -webkit-overflow-scrolling: touch;
}
@media screen and (max-width: 991px) {
  .page-wrapper.nav-is-visible, .cd-main-header.nav-is-visible {
    transform: translateX(-260px);
    left: 0;
    width: 100%;
  }
}

body.is-visible {
  overflow-y: hidden;
}

.cd-main-header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 0;
  display: block;
}
.cd-main-header .cd-header-top-section {
  transition: max-height 0.15s ease-out;
}

.page-wrapper {
  top: 50px;
}

.cd-search {
  position: fixed;
  width: 100%;
  top: 50px;
  display: block;
  transition: top 0.15s ease-out;
}

.cd-nav-logo {
  display: none;
}

@media screen and (min-width: 992px) {
  .page-wrapper {
    top: 180px;
  }

  .cd-main-header.affix {
    height: auto;
  }
  .cd-main-header.affix .cd-header-top-section {
    max-height: 0;
    overflow: hidden;
  }
  .cd-main-header.affix .cd-header-buttons {
    top: 18px;
  }

  .cd-search.affix {
    top: 80px;
  }

  .page-wrapper.affix {
    top: 80px;
  }
}
.cd-overlay {
  position: fixed;
  z-index: 5;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  background-color: rgba(34, 34, 34, 0.8);
  visibility: hidden;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
}
.cd-overlay.is-visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}
@media screen and (max-width: 991px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cd-overlay.is-visible {
    display: none;
  }
}

.cd-main-header {
  height: 50px;
  background: #17457A;
  z-index: 6;
}
@media screen and (min-width: 992px) {
  .cd-main-header {
    background: #E9EBEA;
    height: 180px;
  }
  .cd-main-header::after {
    display: block;
    clear: both;
    content: "";
  }
}

.cd-header-top-section {
  background-color: #17457A;
  position: relative;
}
.cd-header-top-section .container {
  position: relative;
}
@media screen and (min-width: 992px) {
  .cd-header-top-section {
    height: 100px;
    max-height: 100px;
  }
}

.utility-nav {
  float: right;
  margin-top: 37px;
}
.utility-nav li {
  display: inline-block;
  padding: 0 9px;
}
.utility-nav li:last-child {
  padding-right: 0;
}
.utility-nav a {
  color: #ffffff;
  text-decoration: none;
}
.utility-nav a:hover {
  text-decoration: none;
  color: #E9EBEA;
}

.cd-logo {
  position: absolute;
  top: 5px;
  width: 150px;
}
.cd-logo img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}
@media screen and (min-width: 992px) {
  .cd-logo {
    width: 455px;
    top: 14px;
  }
}

.cd-header-buttons {
  position: absolute;
  display: block;
  top: 3px;
  right: 15px;
}
.cd-header-buttons li {
  display: inline-block;
}
@media screen and (min-width: 992px) {
  .cd-header-buttons {
    width: 962px;
    top: 118px;
    margin: 0 auto;
    right: 15px;
    height: 0;
  }
}
@media screen and (min-width: 1200px) {
  .cd-header-buttons {
    width: 1170px;
    right: 4em;
  }
}

.cd-search-trigger, .cd-nav-trigger {
  position: relative;
  display: block;
  width: 44px;
  height: 44px;
  overflow: hidden;
  white-space: nowrap;
  color: transparent !important;
  z-index: 6;
}

.cd-search-trigger::before, .cd-search-trigger::after {
  content: "";
  position: absolute;
  transition: opacity 0.3s;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.cd-search-trigger::before {
  top: 11px;
  left: 11px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 3px solid #ffffff;
}
.cd-search-trigger::after {
  height: 3px;
  width: 8px;
  background: #ffffff;
  bottom: 14px;
  right: 11px;
  transform: rotate(45deg);
}
.cd-search-trigger span {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.cd-search-trigger span::before, .cd-search-trigger span::after {
  content: "";
  position: absolute;
  display: inline-block;
  height: 3px;
  width: 22px;
  top: 50%;
  margin-top: -2px;
  left: 50%;
  margin-left: -11px;
  background: #ffffff;
  opacity: 0;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.3s;
}
.cd-search-trigger span::before {
  transform: rotate(45deg);
}
.cd-search-trigger span::after {
  transform: rotate(-45deg);
}
.cd-search-trigger.search-is-visible::before, .cd-search-trigger.search-is-visible::after {
  opacity: 0;
}
.cd-search-trigger.search-is-visible span::before, .cd-search-trigger.search-is-visible span::after {
  opacity: 1;
}
.cd-search-trigger.search-is-visible span::before {
  transform: rotate(135deg);
}
.cd-search-trigger.search-is-visible span::after {
  transform: rotate(45deg);
}
@media screen and (min-width: 992px) {
  .cd-search-trigger {
    position: absolute;
    right: 0;
    top: 0;
  }
  .cd-search-trigger::before {
    border: 3px solid #17457A;
  }
  .cd-search-trigger span::before, .cd-search-trigger span::after, .cd-search-trigger::after {
    background: #17457A;
  }
}

.cd-nav-trigger span, .cd-nav-trigger span::before, .cd-nav-trigger span::after {
  position: absolute;
  display: inline-block;
  width: 28px;
  left: 8px;
  background: #ffffff;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-origin: 0% 50%;
  transition: all 0.2s linear 0.3s;
}
.cd-nav-trigger span:nth-child(1) {
  top: 13px;
  height: 3px;
}
.cd-nav-trigger span:nth-child(2) {
  top: 13px;
  height: 5px;
  width: 22px;
  left: 11px;
  opacity: 0;
}
.cd-nav-trigger span:nth-child(3) {
  top: 20px;
  height: 3px;
}
.cd-nav-trigger span:nth-child(4) {
  top: 20px;
  height: 5px;
  width: 22px;
  left: 11px;
  opacity: 0;
}
.cd-nav-trigger span:nth-child(5) {
  top: 20px;
  height: 3px;
}
.cd-nav-trigger span:nth-child(6) {
  top: 27px;
  height: 5px;
  width: 22px;
  left: 11px;
  opacity: 0;
}
.cd-nav-trigger span:nth-child(7) {
  top: 27px;
  height: 3px;
}
.cd-nav-trigger.nav-is-visible span:nth-child(1) {
  transform: translateY(-6px);
}
.cd-nav-trigger.nav-is-visible span:nth-child(2) {
  transform: translateY(-2px);
  opacity: 1;
}
.cd-nav-trigger.nav-is-visible span:nth-child(3) {
  transform: translateY(-4px);
}
.cd-nav-trigger.nav-is-visible span:nth-child(4) {
  transform: translateY(0px);
  opacity: 1;
}
.cd-nav-trigger.nav-is-visible span:nth-child(5) {
  transform: translateY(5px);
}
.cd-nav-trigger.nav-is-visible span:nth-child(6) {
  transform: translateY(2px);
  opacity: 1;
}
.cd-nav-trigger.nav-is-visible span:nth-child(7) {
  transform: translateY(7px);
}
@media screen and (min-width: 992px) {
  .cd-nav-trigger {
    display: none;
  }
}

.cd-primary-nav, .cd-primary-nav ul {
  font-family: urw-din, Helvetica, Arial, sans-serif;
  font-size: 16px;
  list-style-type: none;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  min-height: 100%;
  width: 260px;
  background: #17457A;
  padding-left: 0;
  overflow: auto;
  z-index: 1;
  transform: translateZ(0);
  transition: all 0.3s;
}
.cd-primary-nav .row, .cd-primary-nav .col, .cd-primary-nav ul .row, .cd-primary-nav ul .col {
  position: static;
}
@media screen and (max-width: 991px) {
  .cd-primary-nav .row, .cd-primary-nav .col, .cd-primary-nav ul .row, .cd-primary-nav ul .col {
    margin: 0;
    padding: 0;
  }
}
.cd-primary-nav .row, .cd-primary-nav ul .row {
  max-width: 100%;
  overflow-x: hidden;
}
.cd-primary-nav a, .cd-primary-nav ul a {
  display: block;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  color: #ffffff;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  border-bottom: 1px solid #797D82;
  will-change: transform, opacity;
  transition: all 0.3s;
  max-width: 260px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cd-primary-nav .cd-nav-header, .cd-primary-nav ul .cd-nav-header {
  display: block;
  line-height: 30px;
  padding: 40px 20px 0 20px;
  margin: 0;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-bottom: 1px solid #3f4445;
  will-change: transform, opacity;
  transition: all 0.3s;
  max-width: 260px;
  font-size: 12px;
  color: #C7C9C8;
}
.cd-primary-nav.is-hidden, .cd-primary-nav ul.is-hidden {
  transform: translateX(100%);
}
.cd-primary-nav.moves-out > li > a, .cd-primary-nav.moves-out > .row > .row-same-height > .col > li > a, .cd-primary-nav.moves-out > li > .cd-nav-header, .cd-primary-nav.moves-out > .row > .row-same-height > .col > li > .cd-nav-header, .cd-primary-nav ul.moves-out > li > a, .cd-primary-nav ul.moves-out > .row > .row-same-height > .col > li > a, .cd-primary-nav ul.moves-out > li > .cd-nav-header, .cd-primary-nav ul.moves-out > .row > .row-same-height > .col > li > .cd-nav-header {
  transform: translateX(-100%);
  opacity: 0;
}
.cd-primary-nav hr, .cd-primary-nav ul hr {
  display: none;
}

.cd-secondary-nav {
  visibility: visible;
  transition: visibility 0s 0.3s;
}
.cd-secondary-nav.moves-out > li {
  visibility: hidden;
}
@media screen and (min-width: 992px) {
  .cd-secondary-nav.moves-out > li {
    display: none;
  }
}

.cd-primary-nav .see-all a, .cd-primary-nav .see-all-desktop a {
  color: #ffffff;
  font-weight: bold;
}
.cd-primary-nav .see-all-desktop a {
  display: none;
}
.cd-primary-nav .cd-nav-gallery .cd-nav-item, .cd-primary-nav .cd-nav-icons .cd-nav-item {
  height: 80px;
  line-height: 80px;
}
.cd-primary-nav .cd-nav-gallery .cd-nav-item h3, .cd-primary-nav .cd-nav-gallery .cd-nav-item .h3, .cd-primary-nav .cd-nav-icons .cd-nav-item h3, .cd-primary-nav .cd-nav-icons .cd-nav-item .h3 {
  overflow: hidden;
  text-overflow: ellipsis;
}
.cd-primary-nav .cd-nav-gallery .cd-nav-item {
  padding-left: 90px;
}
.cd-primary-nav .cd-nav-gallery .cd-nav-item img {
  position: absolute;
  display: block;
  height: 40px;
  width: auto;
  left: 20px;
  top: 50%;
  margin-top: -20px;
}
.cd-primary-nav .cd-nav-icons .cd-nav-item {
  padding-left: 75px;
  position: relative;
}
.cd-primary-nav .cd-nav-icons .cd-nav-item::before {
  content: "";
  display: block;
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 40px 40px;
}
@media screen and (max-width: 991px) {
  .cd-primary-nav {
    visibility: hidden;
    transition: visibility 0s 0.3s;
  }
  .cd-primary-nav.nav-is-visible {
    visibility: visible;
    transition: visibility 0s 0s;
  }
}

@media screen and (min-width: 992px) {
  .cd-primary-nav a, .cd-primary-nav ul a, .cd-primary-nav ul .cd-nav-header {
    max-width: none;
  }

  .cd-nav {
    background: #E9EBEA;
  }

  .cd-primary-nav {
    margin-bottom: 0;
    position: static;
    padding: 0 0 0 0;
    height: auto;
    width: 100%;
    float: left;
    overflow: visible;
    background: #E9EBEA;
    border-bottom: solid 1px #C7C9C8;
  }
  .cd-primary-nav::after {
    display: block;
    clear: both;
    content: "";
  }
  .cd-primary-nav.moves-out > li > a, .cd-primary-nav.moves-out > .row > .row-same-height > .col > li > a {
    transform: translateX(0);
    opacity: 1;
  }
  .cd-primary-nav ul {
    position: static;
    height: auto;
    width: auto;
    background: transparent;
    overflow: visible;
    z-index: 6;
  }
  .cd-primary-nav ul hr {
    display: block;
  }
  .cd-primary-nav ul.is-hidden {
    transform: translateX(0);
  }
  .cd-primary-nav ul.moves-out > li > a {
    transform: translateX(0);
    opacity: 1;
  }
  .cd-primary-nav > li {
    float: left;
    padding: 0;
  }
  .cd-primary-nav > li:first-child {
    padding-left: 4em;
  }
  .cd-primary-nav > li > a {
    position: relative;
    display: inline-block;
    height: 80px;
    line-height: 80px;
    padding: 0 10px;
    color: #17457A;
    overflow: visible;
    border-bottom: none;
    font-weight: bold;
    transition: color 0.3s, box-shadow 0.3s;
  }
  .cd-primary-nav > li > a:hover {
    color: #17457A;
  }
  .cd-primary-nav > li > a.selected {
    color: #17457A;
    box-shadow: inset 0 -6px 0 #17457A;
  }
  .cd-primary-nav .go-back, .cd-primary-nav .see-all, .cd-primary-nav .see-all-desktop {
    display: none;
  }
  .cd-primary-nav .cd-secondary-nav {
    overflow-y: scroll;
    position: absolute;
    left: 0;
    right: 0;
    top: 80px;
    width: 100%;
    background: #ffffff;
    padding: 35px 64px 30px;
    box-shadow: inset 0 1px 0 #C7C9C8, 0 3px 6px rgba(0, 0, 0, 0.05);
    transform: translateX(0);
    transition: visibility 0s 0s, opacity 0.3s 0s;
  }
  .cd-primary-nav .cd-secondary-nav::after {
    display: block;
    clear: both;
    content: "";
  }
  .cd-primary-nav .cd-secondary-nav.is-hidden {
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s 0.3s, opacity 0.3s 0s;
  }
  .cd-primary-nav .cd-secondary-nav > .see-all-desktop {
    display: block;
    position: relative;
    left: 0;
    bottom: 0;
    height: 80px;
    width: 100%;
    overflow: hidden;
    margin: 15px 0 -30px 0;
    padding: 0;
  }
  .cd-primary-nav .cd-secondary-nav > .see-all-desktop a {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    font-size: 2.2rem;
    font-weight: bold;
    text-align: center;
    line-height: 80px;
    border-top: 1px solid #C7C9C8;
    border-bottom: none;
    margin: 0;
    padding: 0;
    transition: color 0.2s, background 0.2s, border 0.2s;
    color: #17457A;
  }
  .cd-primary-nav .cd-secondary-nav > .see-all-desktop a:hover {
    background: #17457A;
    border-color: #17457A;
    color: #ffffff;
  }
  .cd-primary-nav .cd-secondary-nav .col {
    border-right: 1px solid #C7C9C8;
  }
  .cd-primary-nav .cd-secondary-nav .col:last-child {
    border-right: none;
  }
  .cd-primary-nav .cd-secondary-nav .col > li {
    height: auto;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .cd-primary-nav .cd-secondary-nav .col > li > a {
    color: #17457A;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .cd-primary-nav .cd-secondary-nav .col a {
    height: 30px;
    line-height: 30px;
    padding: 0 18% 0 0;
    color: #333738;
    border-bottom: none;
    transition: all 0.2s;
  }
  .cd-primary-nav .cd-secondary-nav .col a:hover {
    color: #17457A;
  }
  .cd-primary-nav .cd-secondary-nav .col .cd-nav-header {
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
    padding: 0 18% 0 0;
    border-bottom: none;
    color: #CD163F;
  }
  .cd-primary-nav .cd-secondary-nav .col ul {
    transform: translateZ(0);
    padding-left: 0;
  }
  .cd-primary-nav .cd-secondary-nav .col ul ul {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateZ(0);
    opacity: 1;
    transition: all 0.2s;
  }
  .cd-primary-nav .cd-secondary-nav .col ul ul.is-hidden {
    position: absolute;
    transform: translateX(100%);
    opacity: 0;
  }
  .cd-primary-nav .cd-secondary-nav .col ul ul .go-back {
    display: block;
  }
  .cd-primary-nav .cd-secondary-nav .col ul ul .go-back a {
    color: transparent;
  }
  .cd-primary-nav .cd-secondary-nav .col ul ul .see-all {
    display: block;
  }
  .cd-primary-nav .cd-secondary-nav .col .moves-out > li > a {
    transform: translateX(-100%);
    opacity: 0;
    position: absolute;
  }
  .cd-primary-nav .cd-nav-icons .cd-nav-item {
    height: 60px !important;
    line-height: 0.8 !important;
    padding: 24px 0 0 50px !important;
    font-size: 1.5em !important;
    margin-bottom: 0 !important;
  }
  .cd-primary-nav .cd-nav-icons .cd-nav-item::before {
    left: 0;
  }
}
@media screen and (min-width: 1350px) {
  .cd-primary-nav > li {
    padding: 0 0.7em;
  }
}
.has-children > a,
.go-back a,
.is-external > a,
.see-all-content {
  position: relative;
}
.has-children > a::before, .has-children > a::after,
.go-back a::before,
.go-back a::after,
.is-external > a::before,
.is-external > a::after,
.see-all-content::before,
.see-all-content::after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -1px;
  display: inline-block;
  height: 2px;
  width: 10px;
  background: #CD163F;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.has-children > a::before,
.go-back a::before,
.is-external > a::before,
.see-all-content::before {
  transform: rotate(45deg);
}
.has-children > a::after,
.go-back a::after,
.is-external > a::after,
.see-all-content::after {
  transform: rotate(-45deg);
}
@media screen and (min-width: 992px) {
  .has-children > a::before, .has-children > a::after,
.go-back a::before,
.go-back a::after,
.is-external > a::before,
.is-external > a::after,
.see-all-content::before,
.see-all-content::after {
    background: #CD163F;
  }
  .has-children > a:hover::before, .has-children > a:hover::after,
.go-back a:hover::before,
.go-back a:hover::after,
.is-external > a:hover::before,
.is-external > a:hover::after,
.see-all-content:hover::before,
.see-all-content:hover::after {
    background: #CD163F;
  }
}

.has-children > a {
  padding-right: 40px !important;
}
.has-children > a::before, .has-children > a::after {
  right: 20px;
  transform-origin: 9px 50%;
}

.is-external > a::before, .is-external > a::after {
  display: none;
}

.see-all-content {
  font-family: urw-din, Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 30px;
  padding-right: 40px !important;
  text-decoration: none;
  color: #17457A;
}
.see-all-content:hover {
  text-decoration: none;
}
.see-all-content::before, .see-all-content::after {
  height: 4px;
  width: 14px;
  right: 20px;
  transform-origin: 13px 50%;
}
.see-all-content.color-white::before, .see-all-content.color-white::after {
  background-color: #ffffff;
}
.see-all-content.color-white:hover {
  color: #d9d9d9;
}

.cd-primary-nav .go-back a {
  padding-left: 40px;
}
.cd-primary-nav .go-back a::before, .cd-primary-nav .go-back a::after {
  left: 20px;
  transform-origin: 1px 50%;
}

@media screen and (min-width: 992px) {
  .has-children > a {
    width: 100%;
  }
  .has-children > a::before, .has-children > a::after {
    right: 15%;
  }

  .cd-primary-nav > .has-children > a {
    padding-right: 27px !important;
  }
  .cd-primary-nav > .has-children > a::before, .cd-primary-nav > .has-children > a::after {
    width: 9px;
    transform-origin: 50% 50%;
    background: #CD163F;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all 0.3s;
  }
  .cd-primary-nav > .has-children > a::before {
    right: 12px;
  }
  .cd-primary-nav > .has-children > a::after {
    right: 7px;
  }
  .cd-primary-nav > .has-children > a.selected::before, .cd-primary-nav > .has-children > a.selected::after {
    width: 14px;
  }
  .cd-primary-nav > .has-children > a.selected::before {
    transform: translateX(5px) rotate(-45deg);
  }
  .cd-primary-nav > .has-children > a.selected::after {
    transform: rotate(45deg);
  }

  .is-external > a {
    padding-right: 17px !important;
  }
  .is-external > a::before, .is-external > a::after {
    display: inline;
    right: 0;
    width: 9px;
    right: 5px;
    transform-origin: 8px 50%;
  }
  .is-external.color-white > a::before, .is-external.color-white > a::after {
    background: #ffffff;
    width: 8px;
    right: 4px;
    transform-origin: 7px 50%;
  }

  .cd-secondary-nav .row .col > .has-children > a::before, .cd-secondary-nav .row .col > .has-children > a::after {
    visibility: hidden;
    transition: visibility 0s 0.3s;
  }

  .cd-primary-nav .go-back a {
    padding-left: 20px;
  }
  .cd-primary-nav .go-back a::before, .cd-primary-nav .go-back a::after {
    left: 1px;
  }
}
.cd-search {
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
  height: 50px;
  width: 100%;
  top: 50px;
  left: 0;
  z-index: 6;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
}
.cd-search form {
  height: 50px;
  width: 100%;
}
.cd-search input {
  border-radius: 0;
  border: none;
  background: #ffffff;
  height: 100%;
  width: 100%;
  padding: 0 5%;
  -webkit-appearance: none;
}
.cd-search input:focus {
  outline: none;
}
.cd-search.is-visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}
@media screen and (max-width: 991px) {
  .cd-search .container {
    padding: 0;
  }
}
@media screen and (min-width: 992px) {
  .cd-search {
    height: 150px;
    top: 180px;
  }
  .cd-search form {
    height: 150px;
  }
  .cd-search input {
    padding: 0;
    font-size: 3.2rem;
    font-weight: 300;
  }
}

.cd-nav .row > * {
  padding-right: 15px;
  padding-left: 15px;
}

.no-js .cd-primary-nav {
  position: relative;
  height: auto;
  width: 100%;
  left: 0;
  right: 0;
  overflow-y: visible;
  visibility: visible;
  z-index: 2;
}

.no-js .cd-search {
  position: relative;
  top: 0;
  opacity: 1;
  visibility: visible;
}

@media screen and (min-width: 992px) {
  .no-js .cd-primary-nav {
    position: relative;
    z-index: 6;
    display: inline-block;
    width: auto;
    top: 0;
    padding: 0;
  }
}
select.form-control {
  display: block;
  width: 100%;
  height: 46px;
  padding: 10px 16px;
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 1.333333;
  color: #797d82;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.social-nav {
  padding: 12px 0px;
  clear: left;
}
.social-nav h5, .social-nav .h5 {
  font-family: urw-din, Helvetica, Arial, sans-serif;
  font-weight: 300;
  display: inline-block;
  float: left;
  text-transform: uppercase;
  font-size: 20px;
}
.social-nav li {
  display: inline-block;
  float: left;
  margin-left: 10px;
  width: 46px;
}

.social-nav.dark .btn-social {
  color: #17457A;
  border-color: #17457A;
}
.social-nav.dark .btn-social:hover {
  background-color: #17457A;
  color: #ffffff;
}

.social-nav.position-relative {
  top: 0;
  left: 0;
}

.btn-social {
  text-align: center;
  display: inline-block;
  color: #ffffff;
  padding: 12px 0 0 0;
  font-size: 18px;
  height: 46px;
  width: 46px;
  border-radius: 23px;
}
.btn-social i {
  text-align: center;
  display: flex;
  justify-content: space-around;
}

/*
 * CHANGELOG
 * CRAFT B5 - Upgrade bootstrap 3.3.2 - 5.0.2
 * make-md-column replaced with make-col
 */
.related-links {
  font-family: urw-din, Helvetica, Arial, sans-serif;
  background: #E9EBEA;
  padding: 20px 0 30px 0;
  list-style-type: none;
  font-size: 0.9em;
  /*CRAFT B5*/
  /*-CRAFT B5*/
}
.related-links hr {
  border-color: #C7C9C8;
}
.related-links .urgent-issues {
  padding-left: 0;
  margin-bottom: 20px;
  list-style-type: none;
  font-family: urw-din, Helvetica, Arial, sans-serif;
}
@media screen and (min-width: 768px) {
  .related-links .urgent-issues {
    display: inline-block;
  }
}
@media screen and (min-width: 768px) {
  .related-links .urgent-issues li {
    display: inline-block;
    border-left: solid 1px #797D82;
    padding: 0 7px;
  }
}
.related-links .urgent-issues li:first-child {
  border-left: none;
  padding-left: 0;
}
.related-links .urgent-issues a {
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .related-links button {
    margin-left: 14px;
  }
}
@media (min-width: 992px) {
  .related-links .related-topics {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
}
@media (min-width: 992px) {
  .related-links .other-resources {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}
.related-links .other-resources ul,
.related-links .related-topics ul {
  padding-left: 0;
  list-style-type: none;
}
.related-links .other-resources ul li,
.related-links .related-topics ul li {
  margin-top: 10px;
}
.related-links .other-resources ul li:first-child,
.related-links .related-topics ul li:first-child {
  margin-top: 0;
}
.related-links .other-resources ul li a,
.related-links .related-topics ul li a {
  color: #333738;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .related-links--cta .col-md-6:first-of-type {
    border-right: solid 1px #C7C9C8;
  }
}
.related-links--gis {
  background-color: #dddddd;
}
.related-links--gis .col-md-4 > a {
  display: block;
  text-decoration: none;
  transition: opacity 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .related-links--gis .col-md-4 > a {
    transition: none;
  }
}
.related-links--gis .col-md-4 > a p {
  color: #333738;
  margin-bottom: 15px;
}
.related-links--gis .col-md-4 > a:hover {
  opacity: 0.5;
}

@media screen and (min-width: 768px) {
  .related-links--gis .col-md-4 > a {
    color: #000000;
  }
}
footer {
  position: relative;
  width: 100%;
  z-index: 0;
}
@media screen and (min-width: 992px) {
  footer .radio label {
    margin-top: 10px;
  }
}

.footer-2 {
  font-family: urw-din, Helvetica, Arial, sans-serif;
}
.footer-2 ul {
  list-style-type: none;
  padding-left: 0;
}
.footer-2 li {
  font-size: 0.9em;
  margin: 4px 0;
}
@media screen and (min-width: 1200px) {
  .footer-2 li {
    margin: 11px 0;
  }
}
.footer-2 a, .footer-2 a:visited {
  color: #797D82;
  text-decoration: none;
}
.footer-2 a:hover, .footer-2 a:active {
  color: #333738;
}

.footer-3 {
  font-family: urw-din, Helvetica, Arial, sans-serif;
  padding: 15px 0 10px;
}
@media screen and (min-width: 992px) {
  .footer-3 {
    padding: 15px 0 25px;
  }
}

/* ----------------------------------

carousel

-----------------------------------*/
/*
 * CHANGELOGS
 * CRAFT B5 - 09/20/21 - Remove the left margin from first carousel item and adjust carousal for flex based implementation (Bootstrap 5 carousal is flex based)
 */
/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
/* Carousel base class */
.carousel {
  height: 500px;
  min-height: 500px;
  background-color: #f9f9f9;
  position: relative;
}
@media (min-width: 992px) {
  .carousel {
    height: calc(100vh - 180px);
  }
}

.carousel-caption {
  position: relative;
  text-align: left;
  text-transform: uppercase;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: table;
  width: 77%;
  height: 100%;
}
@media (min-width: 992px) {
  .carousel-caption {
    width: 90%;
  }
}
@media (min-width: 1600px) {
  .carousel-caption {
    width: 70%;
  }
}
.carousel-caption .carousel-caption-inner {
  display: table-cell;
  vertical-align: middle;
}
.carousel-caption h1, .carousel-caption .h1 {
  font-size: 2em;
  margin-top: -50px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .carousel-caption h1, .carousel-caption .h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
}
@media (min-width: 992px) {
  .carousel-caption h1, .carousel-caption .h1 {
    font-size: 3em;
    margin-bottom: 25px;
  }
}
@media (min-width: 1200px) and (min-height: 750px) {
  .carousel-caption h1, .carousel-caption .h1 {
    font-size: 5em;
    margin-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .carousel-caption p {
    font-size: 30px;
  }
}
@media (min-width: 992px) {
  .carousel-caption p.lead {
    font-size: 40px;
  }
}
.carousel-caption h1, .carousel-caption .h1, .carousel-caption h2, .carousel-caption .h2, .carousel-caption p, .carousel-caption a {
  color: white;
}
.carousel-caption p {
  font-family: urw-din, Helvetica, Arial, sans-serif;
}
.carousel-caption a {
  font-weight: bold;
  text-decoration: none;
}

.light-slide .carousel-caption {
  color: #222222;
  text-shadow: none;
}
.light-slide .carousel-caption a {
  color: #222222;
  text-shadow: none;
}
.light-slide .video-controls {
  color: #000000;
}

#RachidRules .carousel-inner > .carousel-item > img, #RachidRules .carousel-inner > .carousel-item > picture > img {
  height: 100%;
}

.carousel.fade {
  opacity: 1;
}
.carousel.fade .carousel-item {
  position: relative;
  height: 500px;
  min-height: 500px;
  width: 100%;
  transition: opacity ease-in-out 0.7s;
  left: 0 !important;
  opacity: 0;
  top: 0;
  position: absolute;
  display: block !important;
  z-index: 1;
}
@media (min-width: 992px) {
  .carousel.fade .carousel-item {
    height: calc(100vh - 180px);
  }
}
.carousel.fade .carousel-item:first-child {
  top: auto;
  position: relative;
}
.carousel.fade .carousel-item.active {
  opacity: 1;
  transition: opacity ease-in-out 0.7s;
  z-index: 2;
}

#RachidRules video {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.fallback-container {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.fallback-container .fallback-video {
  position: absolute;
  z-index: 1;
  margin: auto;
  top: 0;
  bottom: 0;
  height: 500px;
  width: auto;
  max-width: none;
  left: 50%;
  transform: translateX(-50%);
}

.carousel.fade .carousel-item {
  background-color: #f9f9f9;
}

.carousel-indicators button {
  margin: 0px;
}

.carousel-indicators {
  background-color: #CD163F;
  background-color: #17457A;
  left: 0;
  bottom: 0;
  margin-left: 0;
  margin-bottom: 0;
  text-align: left;
  padding-left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 4;
  justify-content: flex-start;
}
@media (min-width: 1200px) {
  .carousel-indicators {
    padding-left: 4em;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}
.carousel-indicators [data-bs-target], .carousel-indicators .active {
  font-family: urw-din, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  width: auto;
  max-width: 280px;
  height: 80px;
  text-indent: inherit;
  border-radius: 0;
  color: #ffffff;
  background-color: #CD163F;
  /*CRAFT B5*/
  /*CRAFT B5*/
  border: none;
  border-left: solid 1px #ffffff;
  background-color: #17457A;
  /*CRAFT B5*/
  /*-CRAFT B5*/
  font-size: 12px;
  padding: 8px 4px;
  box-sizing: border-box;
  opacity: 1;
  margin-left: 0;
  margin-right: 0;
  /*CRAFT B5*/
  display: flex;
  align-items: center;
  /*-CRAFT B5*/
}
@media (min-width: 576px) {
  .carousel-indicators [data-bs-target], .carousel-indicators .active {
    font-size: 14px;
    padding: 8px 8px;
  }
}
@media (min-width: 768px) {
  .carousel-indicators [data-bs-target], .carousel-indicators .active {
    font-size: 16px;
    padding: 8px 12px;
  }
}
@media (min-width: 992px) {
  .carousel-indicators [data-bs-target], .carousel-indicators .active {
    font-size: 18px;
    padding: 8px 25px;
  }
}
.carousel-indicators button {
  transition: color 0.3s, box-shadow 0.3s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators button {
    transition: none;
  }
}
.carousel-indicators button:first-child {
  margin-left: 0em;
}
@media (max-width: 991px) {
  .carousel-indicators button:first-child {
    border: none;
  }
}
.carousel-indicators .active {
  box-shadow: inset 0 6px 0 #ffffff;
  box-shadow: none;
  background-color: #CD163F;
}

.video-screen {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
}

/* ----------------------------------

carousel

-----------------------------------*/
/* CUSTOMIZE THE MOBILE FALLBACK
-------------------------------------------------- */
.mobile-carousel {
  height: 500px;
  margin-bottom: 35px;
}

.mobile-carousel .carousel-inner > .item > img {
  height: 500px;
}

.mobile-carousel {
  opacity: 1;
}
.mobile-carousel .item {
  height: 500px;
  background-color: #222222;
  left: 0 !important;
  width: 100%;
  display: block !important;
  z-index: 1;
  top: auto;
  position: relative;
}

/*accordion-items*/
.cd-caret {
  top: -4px;
}
.cd-caret::before, .cd-caret::after {
  /* arrow icon in CSS - for element with nested unordered lists */
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -1px;
  display: inline-block;
  height: 2px;
  width: 9px;
  background: white;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-origin: 50% 50%;
  transition: all 0.1s;
}
.cd-caret::before {
  right: 20px;
  transform: rotate(45deg);
}
.cd-caret::after {
  right: 15px;
  transform: rotate(-45deg);
}

.accordion-item {
  margin: 30px 0px 45px 0px;
  border: none;
}
.accordion-item > .accordion-item-toggle, .accordion-item .accordion-header button {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  /* IE10+ */
  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
  padding: 10px 0;
  background-color: transparent;
  border-bottom: solid 2px #ccc;
  box-shadow: none;
}
.accordion-item > .accordion-item-toggle .cd-caret::before, .accordion-item > .accordion-item-toggle .cd-caret::after, .accordion-item .accordion-header button .cd-caret::before, .accordion-item .accordion-header button .cd-caret::after {
  background: #333738;
}
.accordion-item > .accordion-item-toggle .cd-caret::before, .accordion-item .accordion-header button .cd-caret::before {
  right: 20px;
}
.accordion-item > .accordion-item-toggle .cd-caret::after, .accordion-item .accordion-header button .cd-caret::after {
  right: 15px;
}
.accordion-item > .accordion-item-content {
  display: none;
  padding: 10px 0px 25px 0px;
}
.accordion-item > .accordion-item-content h5, .accordion-item > .accordion-item-content .h5 {
  font-weight: bold;
}
.accordion-item .accordion-collapse .accordion-body {
  padding: 30px 0px 25px 0px;
}
.accordion-item .accordion-collapse h5, .accordion-item .accordion-collapse .h5 {
  font-weight: bold;
}
.accordion-item.open > .accordion-item-content {
  display: block;
}
.accordion-item > h4.accordion-item-toggle, .accordion-item > .accordion-item-toggle.h4, .accordion-item > .accordion-item-content .accordion-item > h5.accordion-item-toggle, .accordion-item > .accordion-item-content .accordion-item > .accordion-item-toggle.h5, .accordion-item .accordion-collapse .accordion-item > h5.accordion-item-toggle, .accordion-item .accordion-collapse .accordion-item > .accordion-item-toggle.h5, .accordion-item h4.accordion-header button, .accordion-item .accordion-header.h4 button, .accordion-item > .accordion-item-content h5.accordion-header button, .accordion-item > .accordion-item-content .accordion-header.h5 button, .accordion-item .accordion-collapse h5.accordion-header button, .accordion-item .accordion-collapse .accordion-header.h5 button {
  color: #17457A;
  padding-right: 25px;
  margin: -10px 0 0 0;
}
@media screen and (min-width: 992px) {
  .accordion-item > h4.accordion-item-toggle, .accordion-item > .accordion-item-toggle.h4, .accordion-item > .accordion-item-content .accordion-item > h5.accordion-item-toggle, .accordion-item > .accordion-item-content .accordion-item > .accordion-item-toggle.h5, .accordion-item .accordion-collapse .accordion-item > h5.accordion-item-toggle, .accordion-item .accordion-collapse .accordion-item > .accordion-item-toggle.h5, .accordion-item h4.accordion-header button, .accordion-item .accordion-header.h4 button, .accordion-item > .accordion-item-content h5.accordion-header button, .accordion-item > .accordion-item-content .accordion-header.h5 button, .accordion-item .accordion-collapse h5.accordion-header button, .accordion-item .accordion-collapse .accordion-header.h5 button {
    padding-right: 60px;
    margin: -20px 0 0 0;
  }
}
.accordion-item > h4.accordion-item-toggle .cd-caret::before, .accordion-item > .accordion-item-toggle.h4 .cd-caret::before, .accordion-item > .accordion-item-content .accordion-item > h5.accordion-item-toggle .cd-caret::before, .accordion-item > .accordion-item-content .accordion-item > .accordion-item-toggle.h5 .cd-caret::before, .accordion-item .accordion-collapse .accordion-item > h5.accordion-item-toggle .cd-caret::before, .accordion-item .accordion-collapse .accordion-item > .accordion-item-toggle.h5 .cd-caret::before, .accordion-item > h4.accordion-item-toggle .cd-caret::after, .accordion-item > .accordion-item-toggle.h4 .cd-caret::after, .accordion-item > .accordion-item-content .accordion-item > h5.accordion-item-toggle .cd-caret::after, .accordion-item > .accordion-item-content .accordion-item > .accordion-item-toggle.h5 .cd-caret::after, .accordion-item .accordion-collapse .accordion-item > h5.accordion-item-toggle .cd-caret::after, .accordion-item .accordion-collapse .accordion-item > .accordion-item-toggle.h5 .cd-caret::after, .accordion-item h4.accordion-header button .cd-caret::before, .accordion-item .accordion-header.h4 button .cd-caret::before, .accordion-item > .accordion-item-content h5.accordion-header button .cd-caret::before, .accordion-item > .accordion-item-content .accordion-header.h5 button .cd-caret::before, .accordion-item .accordion-collapse h5.accordion-header button .cd-caret::before, .accordion-item .accordion-collapse .accordion-header.h5 button .cd-caret::before, .accordion-item h4.accordion-header button .cd-caret::after, .accordion-item .accordion-header.h4 button .cd-caret::after, .accordion-item > .accordion-item-content h5.accordion-header button .cd-caret::after, .accordion-item > .accordion-item-content .accordion-header.h5 button .cd-caret::after, .accordion-item .accordion-collapse h5.accordion-header button .cd-caret::after, .accordion-item .accordion-collapse .accordion-header.h5 button .cd-caret::after {
  background: #17457A;
}
.accordion-item hr {
  border-color: #eee;
}
.accordion-item.accordion-item-default > .accordion-item-toggle {
  text-transform: capitalize;
  color: #17457A;
  border-bottom: solid 2px #ccc;
}
.accordion-item.accordion-item-default > .accordion-item-toggle .cd-caret::before, .accordion-item.accordion-item-default > .accordion-item-toggle .cd-caret::after {
  background: #17457A;
}
.accordion-item.accordion-item-inactive, .accordion-item.accordion-item-primary, .accordion-item.accordion-item-secondary {
  text-align: left;
  margin: 20px 0;
}
.accordion-item.accordion-item-inactive > .accordion-item-toggle, .accordion-item.accordion-item-primary > .accordion-item-toggle, .accordion-item.accordion-item-secondary > .accordion-item-toggle {
  color: white;
  padding: 12px 15px;
  margin-bottom: 0;
}
.accordion-item.accordion-item-inactive > .accordion-item-toggle .cd-caret::before, .accordion-item.accordion-item-inactive > .accordion-item-toggle .cd-caret::after, .accordion-item.accordion-item-primary > .accordion-item-toggle .cd-caret::before, .accordion-item.accordion-item-primary > .accordion-item-toggle .cd-caret::after, .accordion-item.accordion-item-secondary > .accordion-item-toggle .cd-caret::before, .accordion-item.accordion-item-secondary > .accordion-item-toggle .cd-caret::after {
  background: white;
}
.accordion-item.accordion-item-inactive > .accordion-item-toggle .span-right, .accordion-item.accordion-item-primary > .accordion-item-toggle .span-right, .accordion-item.accordion-item-secondary > .accordion-item-toggle .span-right {
  display: block;
  margin-top: 3px;
}
@media (min-width: 992px) {
  .accordion-item.accordion-item-inactive > .accordion-item-toggle .span-right, .accordion-item.accordion-item-primary > .accordion-item-toggle .span-right, .accordion-item.accordion-item-secondary > .accordion-item-toggle .span-right {
    margin-top: 0;
    float: right;
    margin-right: 60px;
  }
}
.accordion-item.accordion-item-inactive > .accordion-item-content, .accordion-item.accordion-item-primary > .accordion-item-content, .accordion-item.accordion-item-secondary > .accordion-item-content {
  background-color: #f2f2f2;
  border: solid 2px #ccc;
  padding: 30px 15px;
}
.accordion-item.accordion-item-inactive > .accordion-item-toggle {
  background-color: #ccc;
  border: solid 2px #ccc;
}
.accordion-item.accordion-item-primary > .accordion-item-toggle {
  background-color: #17457A;
  border: solid 2px #17457A;
}
.accordion-item.accordion-item-secondary > .accordion-item-toggle {
  background-color: #f9a127;
  border: solid 2px #f9a127;
}

.dropdown.open .cd-caret::before, .dropdown.open .cd-caret::after,
.accordion-item.open > .accordion-item-toggle .cd-caret::before, .accordion-item.open > .accordion-item-toggle .cd-caret::after,
.accordion-item > .accordion-header button:not(.collapsed) .cd-caret::before, .accordion-item > .accordion-header button:not(.collapsed) .cd-caret::after {
  width: 14px;
}

.dropdown.open .cd-caret::before,
.accordion-item.open > .accordion-item-toggle .cd-caret::before,
.accordion-item > .accordion-header button:not(.collapsed) .cd-caret::before {
  transform: translateX(5px) rotate(-45deg);
}

.dropdown.open .cd-caret::after,
.accordion-item.open > .accordion-item-toggle .cd-caret::after,
.accordion-item > .accordion-header button:not(.collapsed) .cd-caret::after {
  transform: rotate(45deg);
}

@media (min-width: 992px) {
  .accordion-item {
    margin: 40px 0px 55px 0px;
  }
  .accordion-item .accordion-item {
    padding: 10px 0;
  }
}
.accordion--condensed h3, .accordion--condensed .h3 {
  margin-top: -10px;
  font-size: 18px;
}
.accordion--condensed hr {
  margin-top: 0;
  margin-bottom: 5px;
}
.accordion--condensed .accordion-item-toggle {
  text-decoration: underline;
}
.accordion--condensed .accordion-item {
  margin: 0;
}
.accordion--condensed .accordion-item .accordion-item-toggle {
  padding: 0;
}
@media (min-width: 992px) {
  .accordion--condensed .accordion-item {
    margin: 0;
  }
  .accordion--condensed .accordion-item .accordion-item {
    padding: 10px 0;
  }
}

.nav-tabs--secondary {
  border-bottom: none;
  width: 100%;
}
.nav-tabs--secondary strong {
  display: block;
}
.nav-tabs--secondary > li {
  width: 25%;
}
.nav-tabs--secondary > li > a {
  border: solid 2px #ccc !important;
  margin: 0;
  color: #333738;
}
@media screen and (min-width: media-breakpoint-up(lg)) {
  .nav-tabs--secondary > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.nav-tabs--secondary > li > a:hover {
  border: solid 2px #ccc;
}
.nav-tabs--secondary > li.active > a, .nav-tabs--secondary > li.active > a:hover, .nav-tabs--secondary > li.active > a:focus {
  background: #fff;
  border: solid 2px #ccc;
  cursor: default;
}
.nav-tabs--secondary > li.active > a::after {
  content: "";
  display: block;
  width: 50px;
  height: 30px;
  position: absolute;
  z-index: 1001;
  top: 100%;
  left: 50%;
  transform: translateX(-25px) translateY(0);
  background: transparent url("../img/site/triangle-primary.svg") 0 0 no-repeat;
}
.nav-tabs--secondary .accordion-item {
  margin: 0;
}
.nav-tabs--secondary .accordion-item .accordion-item-toggle {
  padding: 0;
}

.tab-content--secondary {
  border: solid 2px #ccc;
  background: #ffffff;
  padding: 40px 15px 40px 15px !important;
  text-align: left;
}
.tab-content--secondary h3, .tab-content--secondary .h3 {
  margin-top: -10px;
  font-size: 18px;
}
.tab-content--secondary hr {
  margin-top: 0;
  margin-bottom: 5px;
}
.tab-content--secondary .accordion-item-toggle {
  text-decoration: underline;
}
.tab-content--secondary .accordion-item {
  margin: 0;
}
.tab-content--secondary .accordion-item .accordion-item-toggle {
  padding: 0;
}
@media (min-width: 992px) {
  .tab-content--secondary .accordion-item {
    margin: 0;
  }
  .tab-content--secondary .accordion-item .accordion-item {
    padding: 10px 0;
  }
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button::after {
  content: none;
  display: none;
}

.cards hr {
  border-color: #333738;
}
.cards .img-responsive, .cards .card-item--list img, .card-item--list .cards img, .cards .cd-logo img, .cd-logo .cards img, .cards .cd-logo img:first-child + img, .cd-logo .cards img:first-child + img {
  width: 100%;
  max-width: none;
  height: auto;
}

@media (max-width: 768px) {
  .card-item-inner {
    height: auto !important;
  }
}
.card-item {
  margin-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
  touch-action: manipulation;
}

.card-item-inner {
  height: 100%;
  box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.12), 1px 0 2px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  background: white;
}
.card-item-inner:hover {
  box-shadow: -2px 0 2px 0 rgba(0, 0, 0, 0.16), 2px 0 2px 0 rgba(0, 0, 0, 0.16), 0 2px 2px 0 rgba(0, 0, 0, 0.23);
}

.card-item-inner-caption {
  padding: 12px;
}

@media (min-width: 992px) {
  .card-item--image1 {
    position: relative;
  }
  .card-item--image1 p > a {
    color: #333738 !important;
  }
  .card-item--image1.col-lg-8 h2, .card-item--image1.col-lg-8 .h2 {
    font-size: 36px;
  }
  .card-item--image1.col-lg-8 p {
    font-size: 21px;
  }
  .card-item--image1 .card-item-inner-caption {
    position: absolute;
    padding: 12px 24px;
    bottom: 10px;
    z-index: 2;
  }
  .card-item--image1 .card-item-inner-caption h1, .card-item--image1 .card-item-inner-caption .h1, .card-item--image1 .card-item-inner-caption h2, .card-item--image1 .card-item-inner-caption .h2, .card-item--image1 .card-item-inner-caption p, .card-item--image1 .card-item-inner-caption a {
    color: white;
  }
}
.cards--secondary {
  padding-top: 16px;
}

.cards--primary .card-item {
  transition: all, 0.3s;
}
.cards--primary .card-item:hover {
  transform: translate3d(0, -3px, 0);
}

.cards--primary .card-item--image2 img, .cards--secondary img {
  opacity: 0;
  transition: opacity 0.3s ease-in;
  margin-bottom: 0;
}

.card-item--list .card-item-inner {
  position: relative;
  padding: 5px 12px 60px;
}
.card-item--list ul {
  list-style-type: none;
  padding: 0;
}
.card-item--list li {
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: solid 1px #ccc;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.card-item--list img {
  flex: 0 0 auto;
  width: 0.390625%;
  padding-right: 0;
}
.card-item--list h3, .card-item--list .h3 {
  flex: 0 0 auto;
  width: 0.390625%;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: normal;
  color: #333738;
  margin-top: 0;
}
.card-item--list .card-item-view-all {
  display: block;
  border-top: solid 1px #ccc;
  margin-left: -12px;
  margin-right: -12px;
  width: 100%;
  padding: 18px 12px;
  position: absolute;
  bottom: 0;
}

.same-height-container .row {
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (device-width: 768px) {
  .same-height-container .row {
    display: block;
    flex-wrap: nowrap;
  }
}
.same-height-container .card-item {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
@media screen and (device-width: 768px) {
  .same-height-container .card-item {
    display: block;
    display: block;
  }
}

.card-background-img {
  display: block;
  width: 100%;
  height: 200px;
  margin: 0 0 15px 0;
  text-indent: -9999px;
  background-size: cover;
  background-repeat: none;
  background-position: center;
}
@media (min-width: 992px) {
  .card-background-img {
    float: right;
    width: 300px;
    height: 200px;
    margin: 15px;
  }
}

.hero {
  position: relative;
  width: 100%;
  height: 500px;
  margin-bottom: 30px;
  z-index: 1;
  overflow: hidden;
}
.hero img {
  height: 500px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  width: auto;
  max-width: none;
  left: 50%;
  transform: translateX(-50%);
}
.hero .container {
  height: 500px;
}
.hero .hero-caption {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
}
.hero .hero-caption-inner {
  display: table-cell;
  vertical-align: middle;
}
.hero h1, .hero .h1 {
  font-size: 2.5em;
  text-transform: capitalize;
}
.hero h1 a, .hero .h1 a {
  color: white;
}
.hero p {
  font-size: 1.5em;
  color: white;
}

@media (min-width: 992px) {
  .hero h1, .hero .h1 {
    width: 75%;
    font-size: 4em;
  }
  .hero p {
    width: 75%;
    font-size: 1.8em;
  }
}
/*
 * CHANGELOGS
 * CRAFT B5 - upgrade bootstrap 3.3.2 to 5.0.2
 * made .no-touch &:hover a separate class instead of combining classes 
 */
.btn-dynamic-appear {
  position: fixed;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
}
.btn-dynamic-appear.cd-is-visible {
  visibility: visible;
  opacity: 1;
}
.btn-dynamic-appear.cd-fade-out {
  opacity: 0.5;
}

/*CRAFT B5*/
.btn-dynamic-appear.cd-is-visible,
.btn-dynamic-appear.cd-fade-out {
  transition: opacity 0.3s 0s, visibility 0s 0s;
}

.no-touch:hover {
  transition: opacity 0.3s 0s, visibility 0s 0s;
}

/*-CRAFT B5*/
.cd-top {
  bottom: 20px;
  right: 15px;
}

.cd-down {
  position: absolute;
  bottom: 20px;
  right: 15px;
  z-index: 3;
}

.cd-subscribe {
  padding-top: 0;
  bottom: 20px;
  right: 70px;
  transform: scale(1) rotate(0deg) translate3d(0, 0, 0);
}
.cd-subscribe.cd-subscribe-clicked {
  transition: all 0.1s;
  background: white;
  color: #797d82;
  text-transform: none;
  width: 250px;
  opacity: 1;
}
.cd-subscribe.cd-subscribe-clicked i {
  display: none;
}
.cd-subscribe.cd-subscribe--fly-out {
  width: 50px;
  opacity: 0;
}

.cd-subscribe--success,
.cd-subscribe--error {
  display: none;
}

.cd-subscribe--close {
  position: fixed;
  z-index: 4;
  width: 32px;
  height: 32px;
  bottom: 50px;
  right: 60px;
  border-radius: 16px;
  background: white;
  border: solid 1px;
  padding: 1px 11px;
  display: none;
  cursor: pointer;
}
.cd-subscribe--close.cd-fade-out {
  opacity: 1;
}

/*
 * CHANGELOGS
 * CRAFT B5 - 09/20/2021 - Media Item add underline to link on hover
 */
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

.container-fluid--api {
  position: relative;
  margin: 0 15px;
}
@media screen and (min-width: 992px) {
  .container-fluid--api {
    margin: 0 4em;
  }
}

.skip-navigation {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.skip-navigation:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.section {
  padding: 30px 0px;
}
@media screen and (min-width: 768px) {
  .section {
    padding: 60px 0px;
  }
}

.section--about p.lead {
  font-size: 18px;
}

.section--about .embed-responsive,
.section--about .ratio {
  margin-bottom: 20px;
}

@media screen and (min-width: 1180px) {
  .section--about p.lead {
    font-size: 21px;
  }
}
.reverse-form-sizer {
  position: relative;
  /*remove 30px extra padding since bs5 gutters are small*/
  width: 100%;
  margin: 0 -15px 45px -15px;
  background: white;
}
@media screen and (max-width: 768px) {
  .reverse-form-sizer {
    width: calc(100% + 30px);
  }
}
.reverse-form-sizer > div {
  width: 100%;
  padding: 15px;
  margin-top: -29px;
}
@media screen and (min-width: 768px) {
  .reverse-form-sizer > div {
    margin-top: 0;
    padding: 30px 30px 0 30px;
  }
}

@media screen and (min-width: 992px) {
  .right-aligned-content,
.left-aligned-content {
    flex: 0 0 auto;
    width: 75%;
  }
}

.right-aligned-content-title {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  margin-bottom: 25px;
}
@media screen and (min-width: 992px) {
  .right-aligned-content-title div {
    flex: 0 0 auto;
    width: 75%;
    margin-left: 25%;
  }
}

@media screen and (min-width: 992px) {
  .centered-content {
    flex: 0 0 auto;
    width: 66.66666667%;
    margin-left: 16.66666667%;
  }
}

@media screen and (min-width: 992px) {
  .centered-content-wide {
    flex: 0 0 auto;
    width: 83.33333333%;
    margin-left: 8.33333333%;
  }
}

@media screen and (min-width: 768px) {
  .centered-content-extra-wide {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .sidebar {
    flex: 0 0 auto;
    width: 25%;
  }
}
@media screen and (max-width: 992px) {
  .sidebar.nav {
    margin-bottom: 25px;
  }
}
.sidebar .nav-tabs .nav-item {
  background-color: #e9ebea;
}
.sidebar .nav-tags .nav-item .nav-link {
  display: inline-block;
}
.sidebar .nav-item {
  margin-top: 2px;
}
.sidebar .nav-item .nav-link {
  background-color: #e9ebea;
}
.sidebar .nav-item .nav-link.active {
  background-color: #17457a;
}

@media (min-width: 992px) {
  .sidebar-after {
    right: 75%;
  }
}
@media (min-width: 992px) {
  .content-sidebar-after {
    left: 25%;
  }
}
.sidebar h2:first-of-type, .sidebar .h2:first-of-type {
  margin-top: 0;
}

.external-link {
  padding-right: 21px;
  background-image: url("../img/site/external-link.png");
  background-size: 17px;
  background-repeat: no-repeat;
  background-position: center right;
}

.link-pdf {
  padding-left: 21px;
  background-size: 17px;
  background-repeat: no-repeat;
  background-position: center left;
  background-image: url("../img/site/pdf-icon.png");
}

.jumbotron .external-link {
  background-size: 24px;
  padding-right: 29px;
}
@media screen and (min-width: 992px) {
  .jumbotron {
    padding-bottom: 38px;
  }
  .jumbotron h2, .jumbotron .h2 {
    font-size: 3em;
  }
  .jumbotron .external-link {
    width: 30px;
    height: 30px;
  }
}

.media-item {
  border-bottom: solid 1px #E9EBEA;
  margin-bottom: 20px;
  padding-bottom: 10px;
  /*CRAFT B5*/
  /*-CRAFT B5*/
}
.media-item:last-child, .media-item:last-of-type {
  border-bottom: none;
}
.media-item .more {
  text-decoration: none;
}
.media-item.row img.pull-left.thumbnail {
  margin: 10px 15px 10px 0;
}
.media-item h3 a:hover, .media-item .h3 a:hover {
  text-decoration: underline;
}

img.pull-left.thumbnail,
a.pull-left.thumbnail {
  margin: 0 15px 10px 0;
}

.thumbnail.no-border {
  border: none !important;
}

.media-item-date {
  padding-top: 10px;
  border-bottom: solid 2px #17457A;
  position: relative;
}
@media screen and (min-width: 992px) {
  .media-item-date {
    padding-right: 5px;
  }
}
@media screen and (min-width: 1400px) {
  .media-item-date {
    padding-right: 40px;
  }
}
.media-item-date .date-line-1 {
  position: absolute;
  top: -20px;
}
.media-item-date .date-line-1,
.media-item-date .date-line-2 {
  width: 100%;
  display: block;
}
.media-item-date .date-line-2 {
  text-align: right;
  margin-left: auto;
  margin-right: auto;
  max-width: 78%;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .media-item-date .date-line-2 {
    margin-right: 0;
    text-align: left;
  }
}
.media-item-date .month {
  font-family: urw-din, Helvetica, Arial, sans-serif;
  font-size: 16px;
  display: inline-block;
  text-transform: uppercase;
  color: #797D82;
  line-height: 0.6;
  padding-right: 8px;
}
.media-item-date .date {
  font-family: urw-din, Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 3em;
  color: #CD163F;
}
@media screen and (max-width: 1200px) {
  .media-item-date .date {
    font-size: 2.5em;
  }
}
@media screen and (max-width: 850px) {
  .media-item-date .date {
    font-size: 2.2em;
  }
}

.profile {
  margin-bottom: 10px;
  background-color: #E9EBEA;
  padding-right: 10px;
}
.profile::after {
  content: " ";
  display: table;
  clear: both;
}
.profile h3, .profile .h3 {
  margin-top: 0;
  padding-top: 10px;
}
.profile img {
  float: left;
  margin: 0 15px 0 0;
}

.breadcrumb {
  background: none;
  margin-bottom: 15px;
  margin-top: 15px;
  font-family: urw-din, Helvetica, Arial, sans-serif;
  font-size: 0.9em;
  padding-left: 0;
}
.breadcrumb > .active,
.breadcrumb a {
  color: white;
  font-weight: normal;
  text-decoration: none;
}

.img-banner {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  height: 150px;
  overflow: hidden;
}
.img-banner .container {
  position: relative;
}
.img-banner img {
  height: 150px;
  bottom: auto;
  z-index: -1;
}

.share {
  position: absolute;
  left: 15px;
  top: 45px;
}
@media screen and (min-width: 992px) {
  .share {
    left: auto;
    right: 0;
    top: 35px;
  }
}
@media screen and (min-width: 768px) {
  .share {
    top: 28px;
  }
}
@media screen and (max-width: 768px) {
  .share {
    top: 35px;
  }
}

.social-mini-nav {
  position: relative;
  top: 0;
  left: 0;
}
.social-mini-nav::after {
  content: " ";
  display: table;
  clear: both;
}
.social-mini-nav .btn, .social-mini-nav .hubspot-form-submit {
  width: 28px;
  height: 28px;
  border-radius: 14px;
  margin-left: 5px;
  background-size: 28px 28px !important;
  background-color: #333738;
}
.social-mini-nav .btn:first-child, .social-mini-nav .hubspot-form-submit:first-child {
  margin-left: 0;
}

.nav-pills > li > a,
.nav-pills button,
.nav-tabs > li > a,
.nav-tabs button {
  background-color: #E9EBEA;
  border-top: solid 1px #ffffff;
  text-decoration: none;
  font-family: urw-din, Helvetica, Arial, sans-serif;
  color: #333738;
  padding: 10px 15px;
  background-color: #e9ebea;
}
.nav-pills > li > a:hover,
.nav-pills button:hover,
.nav-tabs > li > a:hover,
.nav-tabs button:hover {
  background-color: #c7c9c8 !important;
  color: #333738;
}

.tab-content {
  padding-top: 20px;
}

.nav-tags a,
.nav-related a {
  color: inherit;
}

.nav-tabs > li button.nav-link {
  background-color: #e9ebea;
  border-top: solid 1px #fff;
  color: #000000;
  margin-right: 2px;
  font-weight: 700;
}
.nav-tabs > li button.nav-link.active {
  background-color: white;
  color: #000;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  font-weight: 800;
  color: #000000;
  cursor: default;
}

.table .separator {
  border-bottom: solid 16px #E9EBEA;
}

@media screen and (max-width: 992px) {
  .col-sm-1 .nav-pills,
.col-sm-2 .nav-pills,
.col-sm-3 .nav-pills,
.col-sm-4 .nav-pills,
.col-sm-5 .nav-pills,
.col-sm-6 .nav-pills,
.col-sm-7 .nav-pills,
.col-sm-8 .nav-pills,
.col-sm-9 .nav-pills,
.col-sm-10 .nav-pills,
.col-sm-11 .nav-pills {
    margin-bottom: 20px;
    border-bottom: solid 1px #E9EBEA;
  }
}

.chart {
  margin-bottom: 20px;
}

#digitalResourcesModal .modal-header,
#htmlVideoModal .modal-header {
  padding: 15px 15px 30px;
}

.pagination-wrapper {
  margin: 0 0 20px 0;
}
.pagination-wrapper .pagination {
  margin: 0 10px 10px 0;
  font-family: urw-din, Helvetica, Arial, sans-serif;
}
@media screen and (min-width: 768px) {
  .pagination-wrapper .pagination {
    margin-bottom: 30px;
  }
}
.pagination-wrapper .form-group {
  margin: 0;
  display: inline-block;
  vertical-align: top;
}
@media screen and (min-width: 768px) {
  .pagination-wrapper .form-group {
    float: right;
  }
}
@media screen and (min-width: 768px) {
  .pagination-wrapper .pagination-sort-by {
    margin-right: 10px;
  }
}
.pagination-wrapper.pagination-alphabetical {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.pagination-wrapper.pagination-alphabetical .pagination {
  padding: 0 !important;
  flex: 0 0 auto;
  width: 0.78125%;
}
.pagination-wrapper.pagination-alphabetical .pagination > li > a {
  min-width: 40px;
  display: block;
  margin-bottom: -1px;
  text-decoration: none;
}
.pagination-wrapper.pagination-alphabetical .pagination > li > a.no-targets {
  color: #C7C9C8;
  background-color: #E9EBEA;
  cursor: default;
}
.pagination-wrapper.pagination-alphabetical .pagination > li:first-child > a {
  margin-left: -1px;
}
.pagination-wrapper .rss {
  text-indent: -9999px;
  float: right;
  margin-left: 5px;
  display: inline-block;
  height: 34px;
  width: 34px;
  background-image: url("../img/social_media/ico_social.png");
  background-size: 34px 243px;
  background-position: 0px -139px;
  background-repeat: no-repeat;
}

.downloads-file-size {
  margin-top: -7px;
}

a[href]:after {
  content: "";
}

#main-content img {
  max-width: 100%;
  height: auto;
}

.container .jumbotron {
  padding: 13px 6.5px;
  margin-bottom: 13px;
  color: inherit;
  background-color: #e9ebea;
}
@media screen and (min-width: 992px) {
  .container .jumbotron {
    padding-bottom: 38px;
  }
}
@media screen and (min-width: 768px) {
  .container .jumbotron {
    padding-right: 26px;
    padding-left: 26px;
  }
}

@media screen and (min-width: 768px) {
  .jumbotron {
    padding: 20.8px 0;
  }
}

.w-lg-50 {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .w-lg-50 {
    width: 50%;
  }
}

.left-aligned-content {
  order: 6 !important;
}
@media screen and (min-width: 992px) {
  .left-aligned-content {
    order: -1 !important;
  }
}

.right-aligned-content {
  order: -1 !important;
}
@media screen and (min-width: 992px) {
  .right-aligned-content {
    order: 6 !important;
  }
}

.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  transition: border 0.2s ease-in-out;
}

a .thumbnail {
  margin-right: 15px;
  margin-bottom: 10px;
}

.hubspot-form {
  padding-bottom: 60px;
}

.hubspot-form input[type=text],
.hubspot-form input[type=email],
.form-group input[type=text],
.form-group input[type=email] {
  margin-bottom: 8px;
}

.hubspot-form .hs-interests .hs-form-required {
  display: none;
}

.hubspot-form ul[role=checkbox], .form-group ul[role=checkbox] {
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
}
@media screen and (min-width: 992px) {
  .hubspot-form ul[role=checkbox], .form-group ul[role=checkbox] {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.hubspot-form label, .form-group label {
  color: #333738;
  font-weight: 400;
  display: table;
  margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .hubspot-form label, .form-group label {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 992px) {
  .hubspot-form label, .form-group label {
    flex: 50%;
  }
  .hubspot-form label:nth-of-type(1), .form-group label:nth-of-type(1) {
    margin-right: 8px;
  }
  .hubspot-form label:nth-of-type(2), .form-group label:nth-of-type(2) {
    margin-right: 8px;
    margin-left: 8px;
  }
  .hubspot-form label:nth-of-type(3), .form-group label:nth-of-type(3) {
    margin-right: 8px;
    margin-left: 8px;
  }
  .hubspot-form label:nth-of-type(4), .form-group label:nth-of-type(4) {
    margin-left: 8px;
  }
}

.hubspot-form label input[type=checkbox],
.form-group label input[type=checkbox],
.hubspot-form label span,
.form-group label span {
  display: table-cell;
}

.hubspot-form label span,
.form-group label span {
  padding-left: 5px;
}

.hubspot-form input[type=checkbox],
.form-group input[type=checkbox] {
  height: 13px;
  min-width: 13px;
  padding: 10px 16px;
  width: 100%;
  color: #797d82;
}

.hubspot-form-submit {
  margin-top: 30px;
}

.hubspot-form-submit:hover,
.hubspot-form-submit:focus {
  background-color: #ffffff;
  border-color: #133965;
  color: #17457a;
}

.hubspot-form .hs-error-msg {
  color: #CD163F;
  margin-bottom: 15px;
}

.submitted-message {
  padding-bottom: 60px;
}

.cd-search .CoveoSearchbox .magic-box {
  border: none;
  border-radius: 0;
}

.cd-search .CoveoSearchbox .magic-box .magic-box-input {
  height: 150px;
}

.cd-search .CoveoSearchbox .magic-box .magic-box-input > input {
  color: #333738;
  font-size: 3.2rem;
  font-weight: 300;
  font-family: urw-din, Helvetica, Arial, sans-serif;
  height: 100%;
  padding: 0;
}

.cd-search .CoveoSearchbox .CoveoSearchButton, .page-wrapper .CoveoLogo {
  display: none;
}

#cd-search .magic-box .magic-box-clear {
  display: none;
}

#main-content .api-search-page.CoveoSearchInterface .coveo-search-section {
  max-width: 100%;
  padding: 0;
}

.CoveoSearchInterface {
  min-width: auto !important;
}
.CoveoSearchInterface .coveo-results-column {
  padding-left: 0 !important;
  padding-top: 0 !important;
}

.coveo-list-layout.CoveoResult {
  padding: 5px 0;
}

.CoveoResultLink.nav-link {
  display: block;
  color: #333738;
  background-color: #e9ebea;
}

.CoveoResultLink.nav-link:visited {
  color: #333738;
}

.CoveoResultLink.nav-link:hover {
  text-decoration: none;
}

.api-search-page .CoveoSearchButton:hover .coveo-magnifier-circle-svg,
.api-search-page .CoveoSearchButton:hover svg g path {
  fill: #CD163F;
}

.api-search-page .coveo-results-column .coveo-results-header {
  float: right;
}

.api-search-page .CoveoBreadcrumb {
  margin-bottom: 10px;
}

.api-search-page .coveo-results-column .rss {
  text-indent: -9999px;
  float: right;
  margin-left: 5px;
  display: inline-block;
  height: 34px;
  width: 34px;
  background-image: url(../img/social_media/ico_social.png);
  background-size: 34px 243px;
  background-position: 0 -139px;
  background-repeat: no-repeat;
}

.api-search-page .coveo-results-column .coveo-summary-section {
  display: block;
}

.api-search-page .facet-section-header {
  position: relative;
}

.api-search-page .facet-section-header .CoveoSettings {
  position: absolute;
  top: 0;
  left: 78%;
  height: 30px;
  width: 30px;
}

.api-search-page .facet-section-header .CoveoSettings .coveo-settings-square {
  width: 3px;
  height: 3px;
}

.api-search-page .CoveoPager {
  margin-left: 0;
}

.CoveoQuerySummary {
  margin-left: 10px;
}

@media screen and (min-width: 480px) {
  .api-search-page .coveo-pager-list-item:nth-of-type(1) {
    margin-left: 0;
  }
}
.api-search-page .coveo-list-layout.CoveoResult {
  border-bottom: thin solid #e9ebea;
  padding: 16px 0 16px 0;
}

.api-search-page .coveo-dynamic-facet-header {
  border-bottom: thin solid #e9ebea;
}

.api-search-page .CoveoResultLink, .api-search-page a.CoveoResultLink, .api-search-page .CoveoResult a.CoveoResultLink,
.api-search-page .CoveoResultLink, .api-search-page a.CoveoResultLink, .api-search-page .CoveoResult a.CoveoResultLink:hover,
.api-search-page .CoveoResultLink, .api-search-page a.CoveoResultLink, .api-search-page .CoveoResult a.CoveoResultLink:visited {
  color: #17457a !important;
}

@media screen and (max-width: 992px) {
  .cd-search .CoveoSearchbox .magic-box .magic-box-input {
    height: 50px;
  }

  .cd-search .CoveoSearchbox .magic-box .magic-box-input > input {
    font-size: 18px;
  }
}
/*
* This combined file was created by the DataTables downloader builder:
*   https://datatables.net/download
*
* To rebuild or modify this file with the latest versions of the included
* software please visit:
*   https://datatables.net/download/#bs/dt-1.10.18/b-1.5.4/b-flash-1.5.4/b-html5-1.5.4/b-print-1.5.4
*
* Included libraries:
*   DataTables 1.10.18, Buttons 1.5.4, Flash export 1.5.4, HTML5 export 1.5.4, Print view 1.5.4
*/
table.dataTable {
  clear: both;
  font-family: calibri, Helvetica, Arial, sans-serif;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
}

table.dataTable td,
table.dataTable th {
  box-sizing: content-box;
}

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  text-align: center;
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length select {
  width: 75px;
  display: inline-block;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}

div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

div.dataTables_wrapper div.dataTables_info {
  padding-top: 8px;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0;
}

table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 30px;
}

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: block;
  font-family: "Glyphicons Halflings";
  opacity: 0.5;
}

table.dataTable thead .sorting:after {
  opacity: 0.2;
  content: "\e150";
}

table.dataTable thead .sorting_asc:after {
  content: "\e155";
}

table.dataTable thead .sorting_desc:after {
  content: "\e156";
}

table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  color: #eee;
}

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody > table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody > table > thead .sorting:after,
div.dataTables_scrollBody > table > thead .sorting_asc:after,
div.dataTables_scrollBody > table > thead .sorting_desc:after {
  display: none;
}

div.dataTables_scrollBody > table > tbody > tr:first-child > th,
div.dataTables_scrollBody > table > tbody > tr:first-child > td {
  border-top: none;
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner {
  box-sizing: content-box;
}

div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
  margin-top: 0 !important;
  border-top: none;
}

@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
div.dataTables_wrapper div.dataTables_filter,
div.dataTables_wrapper div.dataTables_info,
div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
}
table.dataTable.table-condensed > thead > tr > th {
  padding-right: 20px;
}

table.dataTable.table-condensed .sorting:after,
table.dataTable.table-condensed .sorting_asc:after,
table.dataTable.table-condensed .sorting_desc:after {
  top: 6px;
  right: 6px;
}

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0;
}

table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
  border-right-width: 0;
}

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
}

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:first-child {
  padding-left: 0;
}

div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:last-child {
  padding-right: 0;
}

@keyframes dtb-spinner {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dtb-spinner {
  100% {
    transform: rotate(360deg);
  }
}
div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: white;
  border: 2px solid #111;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  text-align: center;
  z-index: 21;
}

div.dt-button-info h2, div.dt-button-info .h2 {
  padding: 0.5em;
  margin: 0;
  font-weight: normal;
  border-bottom: 1px solid #ddd;
  background-color: #f3f3f3;
}

div.dt-button-info > div {
  padding: 1em;
}

div.dt-button-collection-title {
  text-align: center;
  padding: 0.3em 0 0.5em;
  font-size: 0.9em;
}

ul.dt-button-collection.dropdown-menu {
  display: block;
  z-index: 2002;
  -moz-column-gap: 8px;
  -ms-column-gap: 8px;
  -o-column-gap: 8px;
  column-gap: 8px;
}

ul.dt-button-collection.dropdown-menu.fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -75px;
  border-radius: 0;
}

ul.dt-button-collection.dropdown-menu.fixed.two-column {
  margin-left: -150px;
}

ul.dt-button-collection.dropdown-menu.fixed.three-column {
  margin-left: -225px;
}

ul.dt-button-collection.dropdown-menu.fixed.four-column {
  margin-left: -300px;
}

ul.dt-button-collection.dropdown-menu > * {
  -moz-column-break-inside: avoid;
  break-inside: avoid;
}

ul.dt-button-collection.dropdown-menu.two-column {
  width: 300px;
  padding-bottom: 1px;
  -moz-column-count: 2;
  -ms-column-count: 2;
  -o-column-count: 2;
  column-count: 2;
}

ul.dt-button-collection.dropdown-menu.three-column {
  width: 450px;
  padding-bottom: 1px;
  -moz-column-count: 3;
  -ms-column-count: 3;
  -o-column-count: 3;
  column-count: 3;
}

ul.dt-button-collection.dropdown-menu.four-column {
  width: 600px;
  padding-bottom: 1px;
  -moz-column-count: 4;
  -ms-column-count: 4;
  -o-column-count: 4;
  column-count: 4;
}

ul.dt-button-collection.dropdown-menu .dt-button {
  border-radius: 0;
}

div.dt-button-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2001;
}

@media screen and (max-width: 767px) {
  div.dt-buttons {
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 0.5em;
  }

  div.dt-buttons a.btn, div.dt-buttons a.hubspot-form-submit {
    float: none;
  }
}
div.dt-buttons button.btn.processing, div.dt-buttons button.processing.hubspot-form-submit,
div.dt-buttons div.btn.processing,
div.dt-buttons div.processing.hubspot-form-submit,
div.dt-buttons a.btn.processing,
div.dt-buttons a.processing.hubspot-form-submit {
  color: rgba(0, 0, 0, 0.2);
}

div.dt-buttons button.btn.processing:after, div.dt-buttons button.processing.hubspot-form-submit:after,
div.dt-buttons div.btn.processing:after,
div.dt-buttons div.processing.hubspot-form-submit:after,
div.dt-buttons a.btn.processing:after,
div.dt-buttons a.processing.hubspot-form-submit:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  box-sizing: border-box;
  display: block;
  content: " ";
  border: 2px solid #282828;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: dtb-spinner 1500ms infinite linear;
  -o-animation: dtb-spinner 1500ms infinite linear;
  -ms-animation: dtb-spinner 1500ms infinite linear;
  -webkit-animation: dtb-spinner 1500ms infinite linear;
  -moz-animation: dtb-spinner 1500ms infinite linear;
}

@media print {
  @page {
    size: landscape;
  }
}
@media screen and (min-width: 768px) {
  .dt-buttons {
    margin-bottom: 10px;
  }

  .dataTables_filter {
    display: inline-block;
    float: right;
  }

  .table-responsive {
    margin-top: 30px;
  }
}
/*-CRAFT B5-*/

/*# sourceMappingURL=bootstrap.css.map */