/*
 * CHANGELOG
 * CRAFT B5 - Upgrade bootstrap 3.3.2 - 5.0.2
 * make-md-column replaced with make-col
 */
// ----------------------------------
//
//footer
//
// ----------------------------------

// Rlated Links
.related-links {
	font-family: $font-family-sans-serif;
	background: $gray-lighter;
	padding: 20px 0 30px 0;
	list-style-type: none;
	font-size: .9em;
	hr {
		border-color: $gray-light;
	}
	.urgent-issues {
		padding-left: 0;
		margin-bottom: 20px;
		list-style-type: none;
		font-family: $font-family-sans-serif;
		@media screen and (min-width: map-get($grid-breakpoints, "md")) { display: inline-block; }
		li {
			@media screen and (min-width: map-get($grid-breakpoints, "md")) {
				display: inline-block;
				border-left: solid 1px $gray;
				padding: 0 7px;
			}
			&:first-child {
				border-left: none;
				padding-left: 0;
			}
		}
		a {
			text-decoration: none;
		}
	}
	button {
		@media screen and (min-width: map-get($grid-breakpoints, "md")) {
			margin-left: 14px;
		}
	}
	/*CRAFT B5*/
	.related-topics {
		//@include make-md-column(8);
		@include media-breakpoint-up(lg){
			@include make-col(8);
		}
	}
	.other-resources {
		//@include make-md-column(4);
		@include media-breakpoint-up(lg){
			@include make-col(4);
		}
	}
	/*-CRAFT B5*/
	.other-resources,
  .related-topics {
    @extend .d-print-none;
		ul {
			padding-left: 0;
			list-style-type: none;
			li {
				margin-top: 10px;
				&:first-child {
					margin-top: 0;
				}
				a {
						color: $gray-dark;
						text-decoration: none;
				}
			}
		}
	}
}

@media screen and (min-width: map-get($grid-breakpoints, "md")) {
  .related-links--cta {
   .col-md-6:first-of-type {
     border-right: solid 1px $gray-light;
   }
  }
}

.related-links--gis {
  background-color: #dddddd;
  .col-md-4 > a {
    display: block;
    text-decoration: none;
    @include transition(opacity .3s);
		p {
			color: $gray-dark;
			margin-bottom: 15px;
		}
    &:hover {
      opacity: 0.5;
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, "md")) {
	.related-links--gis {
	  .col-md-4 > a {
	    color: $gray-darker;
		}
	}
}

footer {
  position: relative;
  //z-index: 1;
  width: 100%;
  z-index: 0;
  //background: $brand-primary;
  //padding-bottom: 5px;
	@media screen and (min-width: map-get($grid-breakpoints, "lg")) {
		.radio label {
			margin-top: 10px;
		}
	}
}

.footer-1  {
  @extend .d-print-none;

}

.footer-2 {
	font-family: $font-family-sans-serif;
  @extend .d-print-none;
	ul {
		list-style-type: none;
		padding-left: 0;
	}
	li {
		font-size: 0.9em;
		margin: 4px 0;
		@media screen and (min-width: map-get($grid-breakpoints, "xl")) { margin: 11px 0; }
	}
	a, a:visited {
		color: $gray;
		text-decoration: none;
	}
	a:hover, a:active {
		color: $gray-dark;
	}
}

.footer-3 {
	font-family: $font-family-sans-serif;
	padding: 15px 0 10px;
	@media screen and (min-width: map-get($grid-breakpoints, "lg")) {
		padding: 15px 0 25px;
	}
}
