//
// Buttons
// --------------------------------------------------

/*
 * CHANGELOGS
 * CRAFT B5 = Change default button font size from $font-size-lg to $font-size-xxl(custom defined)
 * CRAFT B5 - Add missing button properties excluded in the new bootstrap version and fix button-variant parameter ordering issue
 */

// Base styles
// --------------------------------------------------

.btn {
  font-family: $font-family-sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $border-radius-base--button);
  font-size: 14px;
  font-weight: 700;
  line-height: 1.42857143;
  //override Bootstrap and add some Padding
  padding-right: 30px;
  padding-left: 30px;
}

.btn-default {
  border: 2px solid $brand-primary;
  /*CRAFT B5*/
  color: #17457a;
  background-color: transparent;
  /*-CRAFT B5*/
  &:hover,
  &:focus,
  &.focus {
    background-color: $brand-primary;
    color: white;
  }
}

// Alternate buttons
// --------------------------------------------------

.btn-reverse {
  border-width: 2px;
  /*CRAFT B5*/
  @include button-variant($btn-default-bg, $white, $white);
  /*-CRAFT B5*/
}

.btn-reverse {
  &:hover,
  &:focus,
  &.focus {
    //border: 2px solid @brand-primary;
    background-color: white;
    color: $brand-primary;
  }
}

.btn-primary {
  border: 2px solid transparent;
  &:hover,
  &:focus,
  &.focus {
    border: 2px solid $brand-primary;
    background-color: white;
    color: $brand-primary;
  }
}

// Button Sizes
// --------------------------------------------------

// Button Sizes
// --------------------------------------------------

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input

  //override Bootstrap and add some Padding
  //@include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-xxl, $border-radius-large--button);
  padding: 10px 16px;
  line-height: 1.3333333;
  border-radius: 23px;
  font-size: 18px;
  padding-right: 50px;
  padding-left: 50px;
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  @include button-size($padding-small-vertical, $padding-small-horizontal, $font-size-sm, $border-radius-small--button);
}
