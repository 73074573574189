/* ----------------------------------- */
/* ----------------------------------- */
/* ----------------------------------- */
/*           Mega Menu, Codyhouse      */
/* ----------------------------------- */
/* ----------------------------------- */
/* ----------------------------------- */

.cd-main-header {
	@media screen and (max-width:$nav-breakpoint-max) { overflow: hidden; }
	font-family:$font-family-sans-serif;
	font-size: 16px;
	ol, ul {
		list-style-type: none;
	}
}

.page-wrapper {
	position: relative;
	z-index: $content;
	background-color: $white;
	.page-wrapper-inner {
		padding-bottom: 30px;
		@media screen and (min-width:$nav-breakpoint) { padding-bottom: 60px; }
	}
}

// this is the CORE CSS
.page-wrapper, .cd-main-header {
	// Force Hardware Acceleration in WebKit
	position: relative;
	transform: translateZ(0);
	-webkit-transition: -webkit-transform .3s ease-out; /* Changed here */
  -moz-transition: -moz-transform .3s ease-out;
  -o-transition: -o-transform .3s ease-out;
  transition: transform .3s ease-out;
	will-change: transform;
	-webkit-overflow-scrolling: touch;
	@media screen and (max-width:$nav-breakpoint-max) {
		&.nav-is-visible {
			transform: translateX(-$nav-width-S);
			left: 0;
			width: 100%;
		}
	}
}
body {
	&.is-visible {
		overflow-y: hidden;
	}
}

// Affix Styles for sticky nav - Could use cleaning up
.cd-main-header {
	position: fixed;
	top: 0;
	width: 100%;
	height: 0;
	//max-height: 0;
	display: block;
  //transition: max-height 0.15s ease-out;
	.cd-header-top-section{
		transition: max-height 0.15s ease-out;
	}
}
.page-wrapper {
	top: $header-height-S;
}
.cd-search {
	position: fixed;
	width: 100%;
	top: $header-height-S;
	display: block;
	transition: top 0.15s ease-out;
}
.cd-nav-logo {
	display: none;
}

@media screen and (min-width:$nav-breakpoint) {
	.page-wrapper {
		top: ($header-top-height + $header-height-L);
		//transition: top 0.9s ease-out;
	}
	.cd-main-header.affix {
		height: auto;
		//max-height: ($header-top-height + $header-height-L);
		//top: -($header-top-height + $header-height-L);
		.cd-header-top-section{
			//height: 0;
			max-height: 0;
			overflow: hidden;
		}
		.cd-header-buttons {
			top: 18px;
		}
	}
	.cd-search.affix {
		top: $header-height-L;
	}
	.page-wrapper.affix {
		top: $header-height-L;
	}
}

.cd-overlay {
	// shadow layer visible when navigation is active
	position: fixed;
	z-index: $overlay;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	cursor: pointer;
	background-color: rgba(34,34,34, .8);
	visibility: hidden;
	opacity: 0;
	backface-visibility: hidden;
	-webkit-transition: opacity .3s 0s, visibility 0s .3s;
	-moz-transition: opacity .3s 0s, visibility 0s .3s;
	transition: opacity .3s 0s, visibility 0s .3s;
	&.is-visible {
		opacity: 1;
		visibility: visible;
		-webkit-transition: opacity .3s 0s, visibility 0s 0s;
		-moz-transition: opacity .3s 0s, visibility 0s 0s;
		transition: opacity .3s 0s, visibility 0s 0s;
		@media screen and (max-width:$nav-breakpoint-max) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			// IE10+ CSS styles go here
			// Setting overlay to none in mobile view since IE is being silly with z-indexes. I know...
		  	display: none;
		}
	}

}

// Header Container (logo + utility nav + nav)
.cd-main-header {
	height: $header-height-S;
	background: $brand-primary;
	z-index: $above-content;
	@media screen and (min-width:$nav-breakpoint) {
		background: $gray-lighter;
		height: ($header-top-height + $header-height-L);
		@include clearfix;
	}
}

.cd-header-top-section {
	.container {
		position: relative;
	}
	background-color: $brand-primary;
	position: relative;
	@media screen and (min-width:$nav-breakpoint) {
		height: $header-top-height;
		max-height: $header-top-height;
	}
}

.utility-nav {
	float: right;
	margin-top: 37px;
	li {
		display: inline-block;
		padding: 0 9px;
		&:last-child {
			padding-right: 0;
		}
	}
	a {
		color: $white;
		text-decoration: none;
		&:hover {
			text-decoration: none;
			color: $gray-lighter;
		}
	}
}

.cd-logo {
	position: absolute;
	top: 5px;
	width: 150px;
	img {
		@extend .img-responsive;
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		&:first-child + img {
			@extend .img-responsive, .d-none, .d-xl-block;
		}
	}
	@media screen and (min-width:$nav-breakpoint) {
		width: 455px;
		top: 14px;
	}
}

.cd-header-buttons {
	position: absolute;
	display: block;
	//display: inline-block;
	top: 3px;
	right: 15px;
	li {
		display: inline-block;
	}
	@media screen and (min-width:$nav-breakpoint) {
		width: ($nav-breakpoint - 30);
		// height of header-top-section (120) + margin
		top: ($header-top-height + 18);
		margin: 0 auto;
		right: 15px;
		// avoid overlap of the nav container
		height: 0;
	}
	@media screen and (min-width: map-get($grid-breakpoints, xl)) {
		width: (map-get($grid-breakpoints, xl) - 30);
		right: 4em;
	}
}

.cd-search-trigger, .cd-nav-trigger {
	position: relative;
	display: block;
	width: 44px;
	height: 44px;
	overflow: hidden;
	white-space: nowrap;
	// hide text
	color: transparent!important;
	z-index: $above-content;
}

.cd-search-trigger {
	&::before, &::after {
		// search icon
		content: '';
		position: absolute;
		transition: opacity .3s;
		// Force Hardware Acceleration in WebKit
		transform: translateZ(0);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}
	&::before {
		// lens
		top: 11px;
		left: 11px;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		border: 3px solid $white;
	}
	&::after {
		// handle
		height: 3px;
		width: 8px;
		background: $white;
		bottom: 14px;
		right: 11px;
		transform: rotate(45deg);
	}
	span {
		// container for the X icon
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
	}

	span::before, span::after {
		// close icon
		content: '';
		position: absolute;
		display: inline-block;
		height: 3px;
		width: 22px;
		top: 50%;
		margin-top: -2px;
		left: 50%;
		margin-left: -11px;
		background: $white;
		opacity: 0;
		// Force Hardware Acceleration in WebKit
		transform: translateZ(0);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		transition :all .3s;
	}
	span::before {
		transform: rotate(45deg);
	}
	span::after {
		transform: rotate(-45deg);
	}
	&.search-is-visible {
		&::before, &::after {
			// hide search icon
			opacity: 0;
		}
		span::before, span::after {
			// show close icon
			opacity: 1;
		}
		span::before {
			transform: rotate(135deg);
		}
		span::after {
			transform: rotate(45deg);
		}
	}
	@media screen and (min-width:$nav-breakpoint) {
		position: absolute;
		right: 0;
		top: 0;
		&::before {
			border: 3px solid $brand-primary;
		}
		span::before, span::after, &::after {
			background: $brand-primary;
		}
	}
}

.cd-nav-trigger {
	span, span::before, span::after {
		//hamburger icon in CSS
		position: absolute;
    display: inline-block;
    width: 28px;
    left: 8px;
    background: $white;
    // Force Hardware Acceleration in WebKit
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-origin: 0% 50%;
    transition: all .2s linear .3s;
	}
	span:nth-child(1) {
    top: 13px;
    height: 3px;
	}
	span:nth-child(2) {
	    top: 13px;
	    height: 5px;
	    width: 22px;
	    left: 11px;
	    opacity: 0;
	}
	span:nth-child(3) {
	    top: 20px;
	    height: 3px;
	}
	span:nth-child(4) {
	    top: 20px;
	    height: 5px;
	    width: 22px;
	    left: 11px;
	    opacity: 0;
	}
	span:nth-child(5) {
	    top: 20px;
	    height: 3px;
	}
	span:nth-child(6) {
	    top: 27px;
	    height: 5px;
	    width: 22px;
	    left: 11px;
	    opacity: 0;
	}
	span:nth-child(7) {
	    top: 27px;
	    height: 3px;
	}
	&.nav-is-visible {
		span:nth-child(1) {
    	transform: translateY(-6px);
		}
		span:nth-child(2) {
		  transform: translateY(-2px);
		  opacity: 1;
		}
		span:nth-child(3) {
		  transform: translateY(-4px);
		}
		span:nth-child(4) {
		  transform: translateY(0px);
		  opacity: 1;
		}
		span:nth-child(5) {
		  transform: translateY(5px);
		}
		span:nth-child(6) {
		  transform: translateY(2px);
		  opacity: 1;
		}
		span:nth-child(7) {
		  transform: translateY(7px);
		}
	}
	@media screen and (min-width:$nav-breakpoint) {
		display: none;
	}
}

// Nav on mobile
.cd-primary-nav, .cd-primary-nav ul {
	font-family: $font-family-sans-serif;
	font-size: 16px;
	list-style-type: none;
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	min-height: 100%;
	width: $nav-width-S;
	background: $brand-primary;
	padding-left: 0;
	overflow: auto;
	z-index: $below-content;
	// Force Hardware Acceleration in WebKit
	transform: translateZ(0);
	transition: all .3s;
	.row, .col {
		position: static;
		@media screen and (max-width:$nav-breakpoint-max) {
			margin: 0;
			padding: 0;
		}
	}

	.row {
		max-width: 100%;
		overflow-x: hidden;
	}

	a {
		display: block;
		height: 50px;
		line-height: 50px;
		padding: 0 20px;
		color: $white;
		text-decoration: none;
		-webkit-font-smoothing: antialiased;
  		-moz-osx-font-smoothing: grayscale;
		overflow: hidden;
		border-bottom: 1px solid $gray;
		will-change: transform, opacity;
		transition: all .3s;
		max-width:260px;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.cd-nav-header {
		display: block;
		line-height: 30px;
		padding: 40px 20px 0 20px;
		margin: 0;
		text-decoration: none;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		border-bottom: 1px solid lighten($gray-dark, 5%);
		will-change: transform, opacity;
		transition: all .3s;
		max-width:260px;
		font-size: 12px;
		color:$gray-light;
	}
	&.is-hidden {
		// secondary navigations hidden by default
		transform: translateX(100%);
	}
	&.moves-out > li > a,
	&.moves-out > .row > .row-same-height > .col > li > a,
	&.moves-out > li > .cd-nav-header,
	&.moves-out > .row > .row-same-height > .col > li > .cd-nav-header {
		// push the navigation items to the left - and lower down opacity - when secondary nav slides in
		transform: translateX(-100%);
		opacity: 0;
	}
	hr {
		display: none;
	}
}

// The following is needed to debug overflow visibility issues with .row on IOS Safari
.cd-secondary-nav {
	visibility: visible;
	transition: visibility 0s .3s;
	&.moves-out > li {
		visibility: hidden;
		// collapse on desktop
		@media screen and (min-width:$nav-breakpoint) {
			display: none;
		}
	}
}

.cd-primary-nav {
	.see-all a, .see-all-desktop a {
		// different style for the See all button on mobile and tablet
		color: $white;
		font-weight: bold;
	}
	.see-all-desktop a {
		// Avoid duplication on mobile
		display: none;
	}

	.cd-nav-gallery .cd-nav-item, .cd-nav-icons .cd-nav-item {
		// items with picture (or icon) and title
		height: 80px;
		line-height: 80px;
		h3 {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.cd-nav-gallery .cd-nav-item {
		padding-left: 90px;
		img {
			position: absolute;
			display: block;
			height: 40px;
			width: auto;
			left: 20px;
			top: 50%;
			margin-top: -20px;
		}
	}

	.cd-nav-icons .cd-nav-item {
		padding-left: 75px;
		position: relative;
		&::before {
			// item icon
			content:'';
			display: block;
			position: absolute;
			left: 20px;
			top: 50%;
			margin-top: -20px;
			width: 40px;
			height: 40px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 40px 40px;
		}
	}
	@media screen and (max-width:$nav-breakpoint-max) {
		// by default .cd-primary-nav is hidden - trick for iOS devices where you can see the navigation if you pull down
		visibility: hidden;
		transition: visibility 0s .3s;
		&.nav-is-visible {
			visibility: visible;
			transition: visibility 0s 0s;
		}
	}
}

// Nav fitting for small desktops
// @media screen and (min-width: $nav-breakpoint) and (max-width:$screen-md-max) {
// 	.cd-primary-nav .cd-secondary-nav {
// 		padding: 20px!important;
// 	}
// }

// Nav in desktop view
@media screen and (min-width:$nav-breakpoint) {

	.cd-primary-nav a, .cd-primary-nav ul a, .cd-primary-nav ul .cd-nav-header {
		max-width:none;
	}

	.cd-nav {
		background: $gray-lighter;
	}

	.cd-primary-nav {
		margin-bottom: 0;
		position: static;
		padding: 0 0 0 0;
		height: auto;
		width: 100%;
		float: left;
		overflow: visible;
		background: $gray-lighter;
		border-bottom: solid 1px $gray-light;
		@include clearfix;

		&.moves-out > li > a, &.moves-out > .row > .row-same-height > .col > li > a {
			// reset mobile style
			transform: translateX(0);
			opacity: 1;
		}

		ul {
			position: static;
			height: auto;
			width: auto;
			background: transparent;
			overflow: visible;
			z-index: $above-content;

			hr {
				display: block;
			}

			&.is-hidden {
				// reset mobile style
				transform: translateX(0);
			}

			&.moves-out > li > a {
				// reset mobile style
				transform: translateX(0);
				opacity: 1;
			}
		}

		> li {
			float: left;
			padding: 0;
			&:first-child {
				padding-left: 4em;
			}
		}

		> li > a {
			//  navigation buttons style
			position: relative;
			display: inline-block;
			height: $header-height-L;
			line-height: $header-height-L;
			padding: 0 10px;
			color: $brand-primary;
			overflow: visible;
			border-bottom: none;
			font-weight: bold;
			transition: color .3s, box-shadow .3s;

			&:hover {
				color: $brand-primary;
			}

			&.selected {
				color: $brand-primary;
				box-shadow: inset 0 -6px 0 $brand-primary;
			}
		}

		.go-back, .see-all, .see-all-desktop {
			// debugging safari IOS again: follow up on lines 809 and 817
			display: none;
		}

		.cd-secondary-nav {
			// dropdown menu style
			overflow-y: scroll;
			position: absolute;
			left: 0;
			right: 0;
			top: $header-height-L;
			width: 100%;
			background: $white;
			padding: 35px 64px 30px;
			box-shadow: inset 0 1px 0 $gray-light, 0 3px 6px rgba(0,0,0, .05);
			transform: translateX(0);
			transition: visibility 0s 0s, opacity .3s 0s;
			@include clearfix;

			&.is-hidden {
				opacity: 0;
				visibility: hidden;
				transition: visibility 0s .3s, opacity .3s 0s;
			}

			> .see-all-desktop {
				// this is the BIG See all button at the bottom of the dropdown menu
				display: block;
				position: relative;
				left: 0;
				bottom: 0;
				height: 80px;
				width: 100%;
				overflow: hidden;
				// reset some inherited style
				margin: 15px 0 -30px 0;
				padding: 0;
				a {
					display: block;
					position: relative;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					font-size: 2.2rem;
					font-weight: bold;
					text-align: center;
					line-height: 80px;
					border-top: 1px solid $gray-light;
					// reset some inherited style
					border-bottom: none;
					margin: 0;
					padding: 0;
					transition: color .2s, background .2s, border .2s;
					color: $brand-primary;

					&:hover {
						background: $brand-primary;
						border-color: $brand-primary;
						color: $white;
					}
				}
			}
		}

		.cd-secondary-nav .col {
			border-right: 1px solid $gray-light;
			&:last-child {
				border-right: none;
			}
			> li {
				// change the height according to your needs - you can even set height: auto
				height: auto;
				overflow: hidden;
				-webkit-overflow-scrolling: touch;
				margin-bottom: 15px;
				padding-left: 15px;
				padding-right: 15px;

				> a {
					// secondary nav title
					color: $brand-primary;
					font-weight: bold;
					margin-bottom: 5px;
				}
			}

			a {
				height: 30px;
				line-height: 30px;
				padding: 0 18% 0 0;
				color: $gray-dark;
				border-bottom: none;
				transition: all .2s;
				&:hover {
					color: $brand-primary;
				}
			}

			.cd-nav-header {
				font-size: 16px;
				font-weight: bold;
				line-height: 30px;
				padding: 0 18% 0 0;
				border-bottom: none;
				color: $brand-secondary;
			}

			ul {
				// Force Hardware Acceleration in WebKit
				transform: translateZ(0);
				padding-left: 0;
			}

			ul ul {
				// tertiary navigation
				position: relative;
				top: 0;
				left: 0;
				width: 100%;
				transform: translateZ(0);
				opacity: 1;
				transition: all .2s;
				&.is-hidden {
					position: absolute;
					transform: translateX(100%);
					opacity: 0;
				}

				.go-back {
					display: block;
					a {
						color: transparent;
					}

				}

				.see-all {
					display: block;
				}
			}

			.moves-out > li > a {
				// push the navigation items to the left - and lower down opacity - when tertiary nav slides in
				transform: translateX(-100%);
				opacity: 0;
				position: absolute;
			}
		}

		.cd-nav-icons {
			.cd-nav-item {
				height: 60px!important;
				line-height: .8!important;
				padding: 24px 0 0 50px!important;
				font-size: 1.5em!important;
				margin-bottom: 0!important;
				&::before {
					left: 0;
				}
			}
		}
	}
}

@media screen and (min-width: 1350px) {
	.cd-primary-nav {
		> li {
			padding: 0 .7em;
		}
	}
}

// Cool Carets for has-children, go-back and is-external
// see-all-content is used on the homepage
.has-children > a,
.go-back a,
.is-external > a,
.see-all-content {
	position: relative;

	&::before, &::after {
		// arrow icon in CSS - for element with nested unordered lists
		content: '';
		position: absolute;
		top: 50%;
		margin-top: -1px;
		display: inline-block;
		height: 2px;
		width: 10px;
		background: $brand-secondary;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}

	&::before {
		transform: rotate(45deg);
	}

	&::after {
		transform: rotate(-45deg);
	}

	@media screen and (min-width:$nav-breakpoint) {
		&::before, &::after {
			background: $brand-secondary;
		}

		&:hover::before, &:hover::after {
			background: $brand-secondary;
		}
	}
}

.has-children > a {
	padding-right: 40px!important;
	&::before, &::after {
		// arrow goes on the right side - children navigation
		right: 20px;
		transform-origin: 9px 50%;
	}
}

// Hide on mobile since it looks like has-children
.is-external > a {
	&::before, &::after {
		display: none;
	}
}

// see-all-content is used on the homepage
.see-all-content {
	font-family: $font-family-sans-serif;
	font-weight: 700;
	text-transform: uppercase;
	font-size: 30px;
	padding-right: 40px!important;
	text-decoration: none;
	color: $brand-primary;
	&:hover {
		text-decoration: none;
	}
	&::before, &::after {
		// arrow goes on the right side - children navigation
		height: 4px;
		width: 14px;
		right: 20px;
		transform-origin: 13px 50%;
	}
	&.color-white {
		&::before, &::after {
			background-color: $white;
		}
		&:hover {
			color: darken($white, 15%)
		}
	}
}

.cd-primary-nav .go-back a {
	padding-left: 40px;
	&::before, &::after {
		// arrow goes on the left side - go back button
		left: 20px;
		transform-origin: 1px 50%;
	}
}

@media screen and (min-width:$nav-breakpoint) {
	.has-children > a {
		width: 100%;
		&::before, &::after {
			right: 15%;
		}
	}

	.cd-primary-nav > .has-children > a {
		//  navigation arrows on larger devices
		padding-right: 27px !important;
		&::before, &::after {
			width: 9px;
			transform-origin: 50% 50%;
			background: $brand-secondary;
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			transition: all .3s;
		}

		&::before {
			right: 12px;
		}

		&::after {
			right: 7px;
		}

		&.selected::before, &.selected::after {
			width: 14px;
		}

		&.selected::before {
			transform: translateX(5px) rotate(-45deg);
		}

		&.selected::after {
			transform: rotate(45deg);
		}
	}

	// Finish setting up positionning and show the arrows
	.is-external {
		> a {
			padding-right: 17px!important;
			&::before, &::after {
				display: inline;
				right: 0;
				width: 9px;
				right: 5px;
				transform-origin: 8px 50%;
			}
		}
		&.color-white {
			> a {
				//padding-right: 15px!important;
				&::before, &::after {
					background: $white;
					width: 8px;
					right: 4px;
					transform-origin: 7px 50%;
				}
			}
		}
	}

	.cd-secondary-nav .row .col > .has-children > a::before, .cd-secondary-nav .row .col > .has-children > a::after {
		// remove arrows on secondary nav titles
		visibility: hidden;
		transition: visibility 0s .3s;
	}

	.cd-primary-nav .go-back a {
		padding-left: 20px;

		&::before, &::after {
			left: 1px;
		}
	}
}

.cd-search {
	background-color: $white;
	box-shadow: 0 3px 6px rgba(0,0,0, .05);
	height: $search-height-S;
	width: 100%;
	top: $header-height-S;
	left: 0;
	z-index: $above-content;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: opacity .3s 0s, visibility 0s .3s;
	-moz-transition: opacity .3s 0s, visibility 0s .3s;
	transition: opacity .3s 0s, visibility 0s .3s;

	form {
		height: $search-height-S;
		width: 100%;
	}

	input {
		border-radius: 0;
		border: none;
		background: $white;
		height: 100%;
		width: 100%;
		padding: 0 5%;
		-webkit-appearance:none;

		&:focus {
			outline: none;
		}
	}

	&.is-visible {
		opacity: 1;
		visibility: visible;
		-webkit-transition: opacity .3s 0s, visibility 0s 0s;
		-moz-transition: opacity .3s 0s, visibility 0s 0s;
		transition: opacity .3s 0s, visibility 0s 0s;
	}

	@media screen and (max-width:$nav-breakpoint-max) {
		.container {
			padding: 0;
		}
	}


	@media screen and (min-width:$nav-breakpoint) {
		form {
			height: $search-height-L;
		}

		height: $search-height-L;
		top: ($header-height-L + $header-top-height);

		input {
			padding: 0;
			font-size: 3.2rem;
			font-weight: 300;

		}
	}
}

// hide for print
.cd-nav {
	@extend .d-print-none;
}

// add padding to column for nav
.cd-nav {
	.row > * {
		padding-right: 15px;
		padding-left: 15px;
	}
}

// support for no js
.no-js .cd-primary-nav {
	position: relative;
	height: auto;
	width: 100%;
	left: 0;
	right: 0;
	overflow-y: visible;
	visibility: visible;
	z-index: $content;
}

.no-js .cd-search {
	position: relative;
	top: 0;
	opacity: 1;
	visibility: visible;
}

@media screen and (min-width:$nav-breakpoint) {
	.no-js .cd-primary-nav {
		position: relative;
		z-index: $above-content;
		display: inline-block;
		width: auto;
		top: 0;
		padding: 0;
	}
}


select.form-control{
	display: block;
    width: 100%;
    height: 46px;
    padding: 10px 16px;
	margin-bottom: 8px;
    font-size: 18px;
    line-height: 1.333333;
    color: #797d82;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}