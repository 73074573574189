.fa-asterisk.form-control-feedback {
  color: #999;
}

.field-validation-error {
  font-size:.8em;
  font-style:italic;
}

.form-group{
  position: relative;
}

.form-group .form-control-feedback {
  position: absolute;
  right: 25px;
}

.form-group .form-control-feedback{
  top: 37%;
  font-size: 14px;
}

.form-group label:not(.sr-only) ~ .form-control-feedback{
  top: 65%;

  @media screen and (max-width: map-get($grid-breakpoints, md)) {
    top: 73%;
  }
}

.form-group .text-small{
  display: block;
  margin-bottom: 35px;
  em{
    position: absolute;
  }
}

.form-group .fa{
  font-size: 14px;
}