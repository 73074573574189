.social-nav {
  padding: 12px 0px;
  clear: left;

  h5 {
    font-family: $font-family-sans-serif;
    font-weight: 300;
    display: inline-block;
    float: left;
    text-transform: uppercase;
    font-size: 20px;
  }

  li {
    display: inline-block;
    float: left;
    margin-left: 10px;
    width: 46px;
  }
}

.social-nav.dark{

	.btn-social{
		color: $brand-primary;
		border-color: $brand-primary;
	
		&:hover{
			background-color: $brand-primary;
			color: $white;
		}
	}
}

.social-nav.position-relative{
  top: 0;
  left: 0;
}

.btn-social {
  text-align: center;
  display: inline-block;
  color: $white;
  padding: 12px 0 0 0;
  font-size: 18px;
  height: 46px;
  width: 46px;
  border-radius: 23px;

  i {
    text-align: center;
    display: flex;
    justify-content: space-around;
  }
}
