/*
 * CHANGELOGS
 * CRAFT B5 - upgrade bootstrap 3.3.2 to 5.0.2
 * made .no-touch &:hover a separate class instead of combining classes 
 */
.btn-dynamic-appear {
  position: fixed;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  //the button becomes visible
  &.cd-is-visible {
    visibility: visible;
    opacity: 1;
  }
  // if the user keeps scrolling down, the button is out of focus and becomes less visible
  &.cd-fade-out {
    opacity: 0.5;
  }
}

/*CRAFT B5*/
.btn-dynamic-appear.cd-is-visible,
.btn-dynamic-appear.cd-fade-out {
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}

.no-touch {
  &:hover {
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0s;
  }
}
/*-CRAFT B5*/

.cd-top {
  bottom: 20px;
  right: 15px;
}

.cd-down {
  position: absolute;
  bottom: 20px;
  right: 15px;
  z-index: 3;
}

.cd-subscribe {
  padding-top: 0;
  bottom: 20px;
  right: 70px;
  transform: scale(1) rotate(0deg) translate3d(0, 0, 0);
  &.cd-subscribe-clicked {
    transition: all 0.1s;
    background: white;
    color: #797d82;
    text-transform: none;
    width: 250px;
    opacity: 1;
    i {
      display: none;
    }
  }
  &.cd-subscribe--fly-out {
    width: 50px;
    opacity: 0;
  }
}

.cd-subscribe--success,
.cd-subscribe--error {
  display: none;
}

.cd-subscribe--close {
  position: fixed;
  z-index: 4;
  width: 32px;
  height: 32px;
  bottom: 50px;
  right: 60px;
  border-radius: 16px;
  background: white;
  border: solid 1px;
  padding: 1px 11px;
  display: none;
  cursor: pointer;
  &.cd-fade-out {
    opacity: 1; 
  }
}
