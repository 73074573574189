/* ----------------------------------

carousel

-----------------------------------*/

/*
 * CHANGELOGS
 * CRAFT B5 - 09/20/21 - Remove the left margin from first carousel item and adjust carousal for flex based implementation (Bootstrap 5 carousal is flex based)
 */

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
//$carousel-height-mobile:  calc(#{"100vh - 50px"});
$carousel-height-mobile:  500px;
$carousel-height-desktop:  calc(#{"100vh - 180px"});


/* Carousel base class */
.carousel {
	height: $carousel-height-mobile;
	min-height: 500px;
	@media (min-width: map-get($grid-breakpoints, "lg")) {
		height: $carousel-height-desktop;
	}
	background-color: #f9f9f9;
	position: relative;
}

.carousel-caption {
	position:relative;
	text-align: left;
	text-transform: uppercase;
	z-index: 10;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: table;
	width:77%;
	@media (min-width: map-get($grid-breakpoints, "lg")) {
		width:90%;
	}
	// Kinda winging it here
	@media (min-width: 1600px) {
		width:70%;
	}
	height:100%;
	.carousel-caption-inner {
		display:table-cell;
		vertical-align:middle;
	}
	h1 {
		font-size: 2em;
		// Offset the indicators
		margin-top: -50px;
		margin-bottom: 15px;
		@media (min-width: map-get($grid-breakpoints, "md")) {
			font-size: 2.5em;
			margin-bottom: 20px;
		}
		@media (min-width: map-get($grid-breakpoints, "lg")) {
			font-size: 3em;
			margin-bottom: 25px;
		}
		@media (min-width: map-get($grid-breakpoints, "xl")) and (min-height: 750px) {
			font-size: 5em;
			margin-bottom: 30px;
		}
	}
	p {
		@media (min-width: map-get($grid-breakpoints, "lg")) {
			font-size: 30px;
		}
		&.lead {
			@media (min-width: map-get($grid-breakpoints, "lg")) {
				font-size: 40px;
			}
		}
	}
	h1, h2, p, a {
		color: white;
	}
	p {
		font-family: $font-family-sans-serif;
	}
	a {
		font-weight:bold;
		text-decoration: none;
	}
}

.light-slide {
	.carousel-caption {
		color: #222222;
		text-shadow: none;
		a {
			color: #222222;
			text-shadow: none;
		}
	}
	.video-controls {
		color: #000000;
	}
}

#RachidRules .carousel-inner > .carousel-item > img, #RachidRules .carousel-inner > .carousel-item > picture > img {
	height: 100%;
	@extend .img-centered;
}


.carousel.fade {
  	opacity: 1;
	.carousel-item {
		position: relative;
		height: $carousel-height-mobile;
		min-height: 500px;
		@media (min-width: map-get($grid-breakpoints, "lg")) {
			height: $carousel-height-desktop;
		}
		width: 100%;
		-moz-transition: opacity ease-in-out 0.7s;
		-o-transition: opacity ease-in-out 0.7s;
		-webkit-transition: opacity ease-in-out 0.7s;
		transition: opacity ease-in-out 0.7s;
		left: 0 !important;
		opacity: 0;
		top: 0;
		position: absolute;

		display: block !important;
		z-index: 1;
	  &:first-child {
			top: auto;
			position: relative;
		}
		&.active {
			opacity: 1;
			-moz-transition: opacity ease-in-out 0.7s;
			-o-transition: opacity ease-in-out 0.7s;
			-webkit-transition: opacity ease-in-out 0.7s;
			transition: opacity ease-in-out 0.7s;
			z-index: 2;
		}
	}
}

#RachidRules video {
	position: absolute;
	width: 100%;
	height: 100%;
	//display: none;
	z-index: 2;
}

.fallback-container {
	display: none;
	position: absolute;
	top: 0; bottom: 0; left: 0; right: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	.fallback-video {
		position: absolute;
		z-index: 1;
		margin: auto;
		top: 0;
		bottom: 0;
		height: 500px;
		width: auto;
		max-width: none;
		left: 50%;
		-ms-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
		-o-transform: translateX(-50%);
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
	}
}

.carousel.fade .carousel-item {
	background-color: #f9f9f9;
}

// .carousel-control {
// 	z-index: 3;
//   position: absolute;
// 	height: 80px;
// 	top: 41%;
// 	left: 0;
// 	bottom: auto;
// 	width: 50px;
// 	@include opacity(1);
// 	&.left, &.right {background: none;}
// 	.glyphicon-chevron-left, .glyphicon-chevron-right, .icon-prev, .icon-next {font-size: 30px;}
// }


.carousel-indicators button {
	margin: 0px;
}


.carousel-indicators {
	background-color: $brand-secondary;

	// carousel blue bar
	background-color: $brand-primary;

	// Reset Bootstrap 3
	left: 0;
	bottom: 0;
	margin-left: 0;
	margin-bottom: 0;
	text-align: left;
	padding-left: 0;
	width: 100%;
	overflow: hidden;
	z-index: 4;

	justify-content: flex-start;

	@include media-breakpoint-up(xl) {
		padding-left: 4em;
		width: fit-content;
	}
	[data-bs-target], .active {
		font-family: $font-family-sans-serif;
		text-transform: uppercase;
		width: auto;
		max-width: 280px;
		height: 80px;
		text-indent: inherit;
		border-radius: 0;
		color: $white;
		background-color: $brand-secondary;
		/*CRAFT B5*/
		//vertical-align: top;
		/*CRAFT B5*/
		border: none;

		// carousel blue bar
		border-left: solid 1px $white;
		background-color: $brand-primary;

		/*CRAFT B5*/
		//display: table-cell;
		//vertical-align: middle;
		/*-CRAFT B5*/
		font-size: 12px;
		padding: 8px 4px;
		box-sizing: border-box;
		opacity: 1;
		margin-left: 0;
		margin-right: 0;

		/*CRAFT B5*/
		display: flex;
		align-items: center;
		/*-CRAFT B5*/
		@include media-breakpoint-up(sm) {
			font-size: 14px;
			padding: 8px 8px;
		}
		@include media-breakpoint-up(md) {
			font-size: 16px;
			padding: 8px 12px;
		}
		@include media-breakpoint-up(lg) {
			font-size: 18px;
			padding: 8px 25px;
		}
	}
	button {
		@include transition(color .3s, box-shadow .3s);
		//border-top: solid 6px transparent;
				// Match container-fluid--api
		&:first-child {
			//margin-left: 4em;
			margin-left: 0em;

			// carousel blue bar
			@media (max-width: $nav-breakpoint-max) {
				border: none;
			}
		}
	}
	.active {
		box-shadow: inset 0 6px 0 $white;

		// carousel blue bar
		box-shadow: none;
		background-color: $brand-secondary;
	}
}


.video-screen {
	position:absolute;
	z-index:9;
	width:100%;
	height:100%;
}
