/*
 * CHANGELOG 
 * CRAFT B5 - 09/20/21 - File created. Importing custom styles defined in bootstrap 3
 * CRAFT B5 - 04/18/22 - Changing page-link behaviour by removing class and directly targeting anchor link 
 */

//
// HTML
//

html {
  overflow-x: hidden;
}

//
// Headings
//

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 1.1;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

//
// Horizontal Rule
//

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #e9ebea;
  opacity: 1;
}

//
// Lead
//

.lead {
  @include font-size("16px");
  @include media-breakpoint-up(md) {
    @include font-size("21px");
  }
}

//
// Page Header
//
// Originally in bootstrap 3, dropped in bootstrap 4 since it could be reproduced using spacing utilities.
// https://getbootstrap.com/docs/4.0/migration/#typography
// Readding the class
//

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #e9ebea;
}

//
//#main-content
//
.page-wrapper-inner {
  .container {
    @media screen and (max-width: map-get($grid-breakpoints, md)) {
      padding: 0 20px;
    }
  }
}

//
//small
//

small,
.small {
  font-size: 85%;
}

//
//page-item
//
.page-item {
  a {
    padding: 0.875rem 1.375rem;
    position: relative;
    display: block;
    color: #17457a;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #dee2e6;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
      z-index: 2;
      color: #123762;
      background-color: #e9ecef;
      border-color: #dee2e6;
    }

    &:focus {
      z-index: 3;
      color: #123762;
      background-color: #e9ecef;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgb(23 69 122 / 25%);
    }
  }

  &:not(:first-child) {
    a {
      margin-left: -1px;
    }
  }

  &.active {
    a {
      z-index: 3;
      color: #fff;
      background-color: $blue;
      border-color: $blue;

      &:hover{
        color: #fff;
      }
    }
  }
}

//
//hidden
//
.hidden {
  display: none;
  visibility: hidden;
}

//
// breadcrumb-item
//
.breadcrumb-item + .breadcrumb-item::before {
  color: #ccc;
}

//
// blockquote
//
blockquote {
  border-left: 5px solid #e9ebea;
  padding: 10px 20px;
}

//
// glyphicon to font awesome mapping
//
table.dataTable thead tr .sorting::after {
  content: "\f0dc";
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table.dataTable thead tr .sorting_desc:after {
  content: "\f884";
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table.dataTable thead tr .sorting_asc:after {
  content: "\f160";
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//card 
.card{
  @extend .px-4;
}

//tooltip
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before{
  border-left-color: #CD163F;
}