/* ----------------------------------

carousel

-----------------------------------*/
/* CUSTOMIZE THE MOBILE FALLBACK
-------------------------------------------------- */


.mobile-carousel {
	height: 500px;
	margin-bottom: 35px;
}

.mobile-carousel .carousel-inner > .item > img {
	height: 500px;
	@extend .img-centered;
}


.mobile-carousel {
  	opacity: 1;
	.item {
		height: 500px;
		background-color: #222222;
		left: 0 !important;
		width: 100%;
		display: block !important;
		z-index: 1;
		top: auto;
		position: relative;
	}
}
