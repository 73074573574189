/*
 * CHANGELOGS
 * CRAFT B5 - 09/20/2021 - Media Item add underline to link on hover
 */

// CONTENT
.container {
  @media screen and(min-width: 1200px) {
    max-width: 1170px;
  }
}

.container-fluid--api {
  position: relative;
  margin: 0 15px;
  @media screen and (min-width: $nav-breakpoint) {
    margin: 0 4em;
  }
}
.skip-navigation {
  @include visually-hidden;
  @include visually-hidden-focusable;
}

.section {
  padding: 30px 0px;
  @media screen and (min-width: map-get($grid-breakpoints, "md")) {
    padding: 60px 0px;
  }
}

// Homepage
.section--about p.lead {
  font-size: 18px;
}
.section--about .embed-responsive,
.section--about .ratio {
  margin-bottom: 20px;
}
@media screen and (min-width: 1180px) {
  .section--about p.lead {
    font-size: 21px;
  }
}
.reverse-form-sizer {
  position: relative;
  /*remove 30px extra padding since bs5 gutters are small*/
  width: calc(100%);
  @media screen and (max-width: map-get($grid-breakpoints, "md")) {
    width: calc(100% + 30px);
  }
  //width: 100%;
  margin: 0 -15px 45px -15px;
  background: white;
  > div {
    width: 100%;
    padding: 15px;
    margin-top: -29px;
    @media screen and (min-width: map-get($grid-breakpoints, "md")) {
      margin-top: 0;
      padding: 30px 30px 0 30px;
    }
  }
}
.right-aligned-content,
.left-aligned-content {
  @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    @include make-col(9);
  }
}
.right-aligned-content-title {
  @include make-row();
  margin-bottom: 25px;
  @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    div {
      @include make-col(9);
      @include make-col-offset(3);
    }
  }
}
.centered-content {
  @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    @include make-col(8);
    @include make-col-offset(2);
  }
}
.centered-content-wide {
  @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    @include make-col(10);
    @include make-col-offset(1);
  }
}

.centered-content-extra-wide {
  @media screen and (min-width: map-get($grid-breakpoints, "md")) {
    @include make-col(12);
  }
}

.sidebar {
  @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    @include make-col(3);
  }
  &.nav {
    // give the content some breathing room on mobile
    @media screen and (max-width: map-get($grid-breakpoints,"lg")) {
      margin-bottom: 25px;
    }
  }

  .nav-tabs {
    .nav-item {
      background-color: #e9ebea;
    }
  }

  .nav-tags{
    .nav-item{
      .nav-link {
        display: inline-block;
      }
    }
  }

  .nav-item {
    margin-top: 2px;
    .nav-link {
      background-color: #e9ebea;
      &.active {
        background-color: #17457a;
      }
    }
  }
}

// fix
@media (min-width: 992px) {
  .sidebar-after {
    right: 75%;
  }
}
@media (min-width: 992px) {
  .content-sidebar-after {
    left: 25%;
  }
}

.sidebar h2:first-of-type {
  margin-top: 0;
}

// Special Link Styles
.external-link {
  padding-right: 21px;
  background-image: url("../img/site/external-link.png");
  background-size: 17px;
  background-repeat: no-repeat;
  background-position: center right;
}
.link-pdf {
  padding-left: 21px;
  background-size: 17px;
  background-repeat: no-repeat;
  background-position: center left;
  background-image: url("../img/site/pdf-icon.png");
}

// Jumbo button used on GIS pages
.jumbotron {
  @extend .d-print-none;
  .external-link {
    background-size: 24px;
    padding-right: 29px;
  }
  @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    padding-bottom: 38px;
    h2 {
      font-size: 3em;
    }
    .external-link {
      width: 30px;
      height: 30px;
    }
  }
}

// Generic content group
.media-item {
  border-bottom: solid 1px $gray-lighter;
  margin-bottom: 20px;
  padding-bottom: 10px;
  &:last-child,
  &:last-of-type {
    border-bottom: none;
  }
  .more {
    text-decoration: none;
  }
  &.row {
    img.pull-left.thumbnail {
      margin: 10px 15px 10px 0;
    }
  }
  /*CRAFT B5*/
  h3 a:hover {
    text-decoration: underline;
  }
  /*-CRAFT B5*/
}
img.pull-left.thumbnail,
a.pull-left.thumbnail {
  margin: 0 15px 10px 0;
}
.thumbnail {
  &.no-border {
    border: none !important;
  }
}

.media-item-date {
  padding-top: 10px;
  border-bottom: solid 2px $brand-primary;
  position: relative;
  @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    padding-right: 5px;
  }

  @media screen and (min-width: map-get($grid-breakpoints, "xxl")) {
    padding-right: 40px;
  }

  .date-line-1 {
    position: absolute;
    top: -20px;
  }

  .date-line-1,
  .date-line-2 {
    width: 100%;
    display: block;
  }

  .date-line-2 {
    text-align: right;
    margin-left: auto;
    margin-right: auto;
    max-width: 78%;
    width: 100%;

    @media screen and (max-width: map-get($grid-breakpoints, "md")) {
      margin-right: 0;
      text-align: left;
    }
  }

  .month {
    font-family: $font-family-sans-serif;
    font-size: 16px;
    display: inline-block;
    text-transform: uppercase;
    color: $gray;
    line-height: 0.6;
    padding-right: 8px;
  }

  .date {
    font-family: $font-family-sans-serif;
    font-weight: 700;
    font-size: 3em;

    @media screen and (max-width: map-get($grid-breakpoints, "xl")) {
      font-size: 2.5em;
    }

    @media screen and (max-width: 850px) {
      font-size: 2.2em;
    }

    color: $brand-secondary;
  }
}

// Profile content group
.profile {
  margin-bottom: 10px;
  background-color: $gray-lighter;
  padding-right: 10px;
  &::after {
    content: " ";
    display: table;
    clear: both;
  }
  h3 {
    margin-top: 0;
    padding-top: 10px;
  }
  img {
    float: left;
    margin: 0 15px 0 0;
  }
}

// Breadcrumbs
.breadcrumb {
  background: none;
  margin-bottom: 15px;
  margin-top: 15px;
  font-family: $font-family-sans-serif;
  font-size: 0.9em;
  padding-left: 0;
  @extend .d-none;
  @extend .d-md-flex;
  > .active,
  a {
    color: white;
    font-weight: normal;
    text-decoration: none;
  }
}

// Banners
.img-banner {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  height: 150px;
  overflow: hidden;
  .container {
    position: relative;
  }
  img {
    height: 150px;
    @extend .img-centered;
    bottom: auto;
    z-index: -1;
  }
}

.share {
  position: absolute;
  left: 15px;
  top: 45px;
  @media screen and (min-width: map-get($grid-breakpoints,"lg")) {
    left: auto;
    right: 0;
    top: 35px;
  }

  @media screen and (min-width: map-get($grid-breakpoints,"md")) {
    top: 28px;
  }

  @media screen and (max-width: map-get($grid-breakpoints,"md")) {
    top: 35px;
  }
}

.social-mini-nav {
  position: relative;
  top: 0;
  left: 0;
  &::after {
    content: " ";
    display: table;
    clear: both;
  }
  .btn {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    margin-left: 5px;
    -webkit-background-size: 28px 28px !important;
    -moz-background-size: 28px 28px !important;
    -o-background-size: 28px 28px !important;
    background-size: 28px 28px !important;
    background-color: $gray-dark;
    &:first-child {
      margin-left: 0;
    }
  }
}

// Other Content Elements
.nav-pills,
.nav-tabs {
  > li > a,
  button {
    background-color: $gray-lighter;
    border-top: solid 1px $white;
    text-decoration: none;
    font-family: $font-family-sans-serif;
    color: $gray-dark;
    padding: 10px 15px;
    background-color: #e9ebea;
    &:hover {
      background-color: #c7c9c8 !important;
      color: $gray-dark;
    }
  }
}

.nav-pills {
  @extend .d-print-none;
}

.tab-content {
  padding-top: 20px;
}

.nav-tags,
.nav-related {
  a {
    color: inherit;
  }
}

// Tabs
// -------------------------

// Give the tabs something to sit on
.nav-tabs {
  > li {
    // Active state, and its :hover to override normal :hover
    button.nav-link {
      background-color: #e9ebea;
      border-top: solid 1px #fff;
      color: $gray-darker;
      margin-right: 2px;
      font-weight: 700;

      &.active {
        background-color: white;
        color: #000;
      }
    }

    &.active > a {
      &,
      &:hover,
      &:focus {
        font-weight: 800;
        color: $gray-darker;
        cursor: default;
      }
    }
  }
}

.table {
  .separator {
    border-bottom: solid 16px $gray-lighter;
  }
}

.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11 {
  .nav-pills {
    @media screen and (max-width: map-get($grid-breakpoints, "lg")) {
      margin-bottom: 20px;
      border-bottom: solid 1px $gray-lighter;
    }
  }
}

.chart {
  margin-bottom: 20px;
}

#digitalResourcesModal .modal-header,
#htmlVideoModal .modal-header {
  padding: 15px 15px 30px;
}

.pagination-wrapper {
  margin: 0 0 20px 0;
  .pagination {
    margin: 0 10px 10px 0;
    @media screen and (min-width: map-get($grid-breakpoints, "md")) {
      margin-bottom: 30px;
    }
    font-family: $font-family-sans-serif;
  }
  .form-group {
    margin: 0;
    display: inline-block;
    vertical-align: top;
    @media screen and (min-width: map-get($grid-breakpoints, "md")) {
      float: right;
    }
  }
  .pagination-sort-by {
    @media screen and (min-width: map-get($grid-breakpoints, "md")) {
      margin-right: 10px;
    }
  }
  &.pagination-alphabetical {
    @include make-row();
    .pagination {
      padding: 0 !important;
      @include make-col(6, map-get($grid-breakpoints, "md"));
      > li {
        > a {
          min-width: 40px;
          display: block;
          margin-bottom: -1px;
          text-decoration: none;
          &.no-targets {
            color: $gray-light;
            background-color: $gray-lighter;
            cursor: default;
          }
        }
        &:first-child {
          > a {
            margin-left: -1px;
          }
        }
      }
    }
  }
  .rss {
    text-indent: -9999px;
    float: right;
    margin-left: 5px;
    display: inline-block;
    height: 34px;
    width: 34px;
    background-image: url("../img/social_media/ico_social.png");
    -webkit-background-size: 34px 243px;
    -moz-background-size: 34px 243px;
    background-size: 34px 243px;
    background-position: 0px -139px;
    background-repeat: no-repeat;
  }
}

.downloads-file-size {
  margin-top: -7px;
}

// Override print CSS that prints out link hrefs
a[href]:after {
  content: "";
}

// Responsive images
#main-content img {
  max-width: 100%;
  height: auto;
}

.container {
  .jumbotron {
    padding: 13px 6.5px;
    margin-bottom: 13px;
    color: inherit;
    background-color: #e9ebea;

    @media screen and (min-width: 992px) {
      & {
        padding-bottom: 38px;
      }
    }

    @media screen and (min-width: 768px) {
      & {
        padding-right: 26px;
        padding-left: 26px;
      }
    }
  }
}

.jumbotron {
  @media screen and (min-width: 768px) {
    & {
      padding: 20.8px 0;
    }
  }
}

.w-lg-50 {
  width: 100%;

  @media screen and (min-width: 768px) {
    & {
      width: 50%;
    }
  }
}

//
.left-aligned-content {
  order: 6 !important;
  @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    order: -1 !important;
  }
}
.right-aligned-content {
  order: -1 !important;
  @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
    order: 6 !important;
  }
}

//thumbnail
.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}

a{
  .thumbnail{
   margin-right: 15px;
   margin-bottom: 10px; 
  }
}
