/*accordion-items*/

.cd-caret {
  top: -4px;
  &::before, &::after {
    /* arrow icon in CSS - for element with nested unordered lists */
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -1px;
    display: inline-block;
    height: 2px;
    width: 9px;
    background: white;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-origin: 50% 50%;
    transition: all .1s;
  }

  &::before {
    right: 20px;
    transform: rotate(45deg);
  }

  &::after {
    right: 15px;
    transform: rotate(-45deg);
  }
}


//accordion-itemS
.accordion-item {
  margin: 30px 0px 45px 0px;
  border: none;
 
  > .accordion-item-toggle, .accordion-header button {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;
    padding: 10px 0;
    background-color: transparent;
    border-bottom: solid 2px #ccc;
    box-shadow: none;
 
    .cd-caret {
      &::before, &::after {
        background: $text-color;
      }
      &::before {
        right: 20px;
      }
      &::after {
        right: 15px;
      }
    }
  }
  > .accordion-item-content {
    //font-size: .9em;
    display: none;
    padding: 10px 0px 25px 0px;
    h5 {
      @extend h4;
      font-weight: bold;
    }
  }

  .accordion-collapse{
    
    .accordion-body{
      padding: 30px 0px 25px 0px;
    }

    h5 {
      @extend h4;
      font-weight: bold;
    }
  }

  &.open > .accordion-item-content {
    display: block;
  }

  > h4.accordion-item-toggle, h4.accordion-header button {
    @extend h4;
    color: $brand-primary;
    padding-right: 25px;
    margin: -10px 0 0 0;
    @media screen and (min-width: map-get($grid-breakpoints, "lg")) {
      padding-right: 60px;
      margin: -20px 0 0 0;
    }
    .cd-caret {
      &::before, &::after {
        background: $brand-primary;
      }
    }
  }
  hr {
    border-color: #eee;
  }

  &.accordion-item-default {
    > .accordion-item-toggle {
      text-transform: capitalize;
      color: $brand-primary;
      border-bottom: solid 2px #ccc;
      .cd-caret {
        &::before, &::after {
          background: $brand-primary;
        }
      }
    }
    > .accordion-item-content {
    }
  }


  &.accordion-item-inactive, &.accordion-item-primary, &.accordion-item-secondary {
    text-align: left;
    margin: 20px 0;
    > .accordion-item-toggle {
      color: white;
      padding: 12px 15px;
      margin-bottom: 0;
      .cd-caret {
       &::before, &::after {
         background: white;
       }
      }
      .span-right {
        display: block;
        margin-top: 3px;
        @media (min-width: map-get($grid-breakpoints, "lg")) {
          margin-top: 0;
          float: right;
          margin-right: 60px;
        }
      }
    }
    > .accordion-item-content {
      background-color: #f2f2f2;
      border: solid 2px #ccc;
      padding:30px 15px;
    }
  }

  &.accordion-item-inactive {
    > .accordion-item-toggle {
      background-color: #ccc;
      border: solid 2px #ccc;
    }
  }

  &.accordion-item-primary {
    > .accordion-item-toggle {
      background-color: $brand-primary;
      border: solid 2px $brand-primary;
    }
  }

  &.accordion-item-secondary {
    > .accordion-item-toggle {
      background-color: #f9a127;
      border: solid 2px #f9a127;
    }
  }
}

.dropdown.open .cd-caret::before, .dropdown.open .cd-caret::after,
.accordion-item.open > .accordion-item-toggle .cd-caret::before, .accordion-item.open > .accordion-item-toggle .cd-caret::after,
.accordion-item > .accordion-header button:not(.collapsed) .cd-caret::before, .accordion-item > .accordion-header button:not(.collapsed) .cd-caret::after
{
  width: 14px;
}
.dropdown.open .cd-caret::before,
.accordion-item.open > .accordion-item-toggle .cd-caret::before,
.accordion-item > .accordion-header button:not(.collapsed) .cd-caret::before {
transform: translateX(5px) rotate(-45deg);
}
.dropdown.open .cd-caret::after,
.accordion-item.open > .accordion-item-toggle .cd-caret::after,
.accordion-item > .accordion-header button:not(.collapsed) .cd-caret::after {
transform: rotate(45deg);
}

@media (min-width: map-get($grid-breakpoints, "lg")) {

.accordion-item {

    margin: 40px 0px 55px 0px;

    .accordion-item {
      // only the nested ones
      padding: 10px 0;
    }
}
}


.accordion--condensed {
h3 {
  margin-top: -10px;
  //margin-bottom: 0;
  font-size: 18px;
}
hr {
  margin-top: 0;
  margin-bottom: 5px;;
}
.accordion-item-toggle {
  text-decoration: underline;
}

.accordion-item {
  margin: 0;
  .accordion-item-toggle {
    padding: 0;
  }
  @media (min-width: map-get($grid-breakpoints, "lg")) {
    margin: 0;
    .accordion-item {
      // only the nested ones
      padding: 10px 0;
    }
  }
}
}


.nav-tabs--secondary {
  border-bottom: none;
  width: 100%;
  strong {
    display: block;
  }
 > li {
  width: 25%;
  > a {
   border: solid 2px #ccc!important;
   margin: 0;
   color: $text-color;
   @media screen and (min-width: media-breakpoint-up(lg)) {
     padding-top: 15px;
     padding-bottom: 15px;
   }
   &:hover {
     border: solid 2px #ccc;
   }
  }
  &.active > a {
    &,
    &:hover,
    &:focus {
      background: #fff;
      border: solid 2px #ccc;
      //color: white;
      cursor: default;
    }
  }
  &.active > a {
   &::after {
     content: '';
     display: block;
     width: 50px;
     height: 30px;
     position: absolute;
     z-index: 1001;
     top: 100%;
     left: 50%;
     transform: translateX(-25px) translateY(0);
     background: transparent url('../img/site/triangle-primary.svg') 0 0 no-repeat;
   }
  }
}
.accordion-item {
  margin: 0;
  .accordion-item-toggle {
    padding: 0;
  }
}
}

.tab-content--secondary {
border: solid 2px #ccc;
background: #ffffff;
padding: 40px 15px 40px 15px!important;
text-align: left;
h3 {
  margin-top: -10px;
  //margin-bottom: 0;
  font-size: 18px;
}
hr {
  margin-top: 0;
  margin-bottom: 5px;;
}
.accordion-item-toggle {
  text-decoration: underline;
}

.accordion-item {
  margin: 0;
  .accordion-item-toggle {
    padding: 0;
  }
  @media (min-width: map-get($grid-breakpoints, "lg")) {
    margin: 0;
    .accordion-item {
      // only the nested ones
      padding: 10px 0;
    }
  }
}
}

.accordion-button:focus{
  box-shadow: none;
}

.accordion-button{
  &::after{
    content: none;
    display: none;
  }
}