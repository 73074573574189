.cards {
	hr {
		border-color:$text-color;
	}
	.img-responsive {
		width: 100%;
		max-width: none;
		height: auto;
	}
}

@media (max-width: map-get($grid-breakpoints, "md")) {
	.card-item-inner {
		height: auto!important;
	}
}

.card-item {
	margin-bottom: 16px;
	padding-left: 8px;
	padding-right: 8px;
	touch-action: manipulation;
}
.card-item-inner {
	height: 100%;
	box-shadow: -1px 0 2px 0 rgba(0,0,0,0.12) , 1px 0 2px 0 rgba(0,0,0,0.12) , 0 1px 1px 0 rgba(0,0,0,0.24);
	background: white;
	&:hover {
		box-shadow: -2px 0 2px 0 rgba(0,0,0,0.16) , 2px 0 2px 0 rgba(0,0,0,0.16) , 0 2px 2px 0 rgba(0,0,0,0.23);
	}
}
.card-item-inner-caption {
	padding: 12px;
}

// Responsive typography
@media (min-width: map-get($grid-breakpoints, "lg")) {
	.card-item--image1 {
		position: relative;
		p > a {
			color:$text-color!important;
		}
		&.col-lg-8 {
			h2 {
				font-size: 36px;
			}
			p {
				font-size: 21px;
			}
		}
		.card-item-inner-caption {
			position: absolute;
			padding: 12px 24px;
			bottom: 10px;
			z-index: 2;
			h1, h2, p, a {
				color: white;
			}
		}
	}
}

.cards--secondary {
	padding-top: 16px;
}

// Hover effect
.cards--primary .card-item {
	transition: all, 0.3s;
	&:hover {
		transform: translate3d(0, -3px, 0);
	}
}

// Image Load Effects
.cards--primary .card-item--image2, .cards--secondary {
		img {
			opacity: 0;
			transition: opacity .3s ease-in;
			margin-bottom: 0;
		}
}

.card-item--list {
	.card-item-inner {
		position: relative;
		padding: 5px 12px 60px;
	}
	ul {
		list-style-type:none;
		padding: 0;
	}
	li {
		padding-top: 10px;
		padding-bottom: 10px;
		border-top: solid 1px #ccc;
		@include make-row();
	}
	img {
		@include make-col(3, map-get($grid-breakpoints, "md"));
		padding-right: 0;
		@extend .img-responsive;
	}
	h3 {
		@include make-col(3, map-get($grid-breakpoints, "md"));
		font-size: $font-size-base;
		line-height: $line-height-base;
		font-weight: normal;
		color: $text-color;
		margin-top: 0;
	}
	.card-item-view-all {
		display: block;
		border-top: solid 1px #ccc;
		margin-left: -12px;
		margin-right: -12px;
		width: 100%;
		padding: 18px 12px;
		position: absolute;
		bottom: 0;
	}
}

// Same height cards
.same-height-container {
	.row {
	  margin-bottom: 0;
	  display: -webkit-box;
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display:         flex;
	  flex-wrap: wrap;
		// General iPad layouts
		@media screen and (device-width: map-get($grid-breakpoints, "md")) {
			display: block;
			flex-wrap: nowrap;
		}
	}
 .card-item {
	  padding-left: 15px;
	  padding-right: 15px;
	  display: flex;
	  flex-direction: column;
	  -webkit-flex-direction: column;
	  margin-bottom: 30px;
		// General iPad layouts
		@media screen and (device-width: map-get($grid-breakpoints, "md")) {
			display: block;
			display: block;
		}
	}
}

// Image cards
.card-background-img {
  display: block;
  width: 100%;
  height: 200px;
  margin: 0 0 15px 0;
  text-indent: -9999px;
  background-size: cover;
  background-repeat: none;
  background-position: center;
	@media (min-width: 992px){
    float: right;
    width: 300px;
    height: 200px;
    margin: 15px;
  }
}


// hero
.hero {
	position: relative;
	width: 100%;
	height: 500px;
	margin-bottom: 30px;
	z-index: $below-content;
	overflow: hidden;
	img {
		height: 500px;
		position: absolute;
		margin: auto;
		top: 0;
		bottom: 0;
		width: auto;
		max-width: none;
		left: 50%;
		-ms-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
		-o-transform: translateX(-50%);
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
	}
	.container {
		height: 500px;
	}
	.hero-caption {
		position: relative;
		display: table;
		width: 100%;
		height: 100%;
	}
	.hero-caption-inner {
		display:table-cell;
		vertical-align:middle;
	}
	h1 {
		font-size: 2.5em;
		text-transform: capitalize;
		a {
			color: white;
		}
	}
	p {
		font-size: 1.5em;
		color: white;
	}
}

@media (min-width: map-get($grid-breakpoints, "lg")) {
	.hero {
		h1{
			width: 75%;
			font-size: 4em;
		}
		p {
			width: 75%;
			font-size: 1.8em;
		}
	}
}
