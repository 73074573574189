/* --------------------------------

Primary style

-------------------------------- */
*::after, *::before {
	box-sizing: border-box;
}


html {
	font-size: 62.5%;
}

body {
	font-size: 1.8em;
	font-family: $font-family-sans-serif;
	font-weight: normal;
	color: $gray-dark;
	background: lighten($gray-base, 97.5%);
	&.overflow-hidden {
		height: 100vh;
	}
}
h1, h2, .h1, .h2 {

	font-family: $font-family-sans-serif;
	font-weight: 700;
	color: $brand-primary;
	a { text-decoration: none; }
}
h1, .h1 {
	font-size: 3em;
	margin-bottom: 30px;
}

// h1.long-title {
// 	// font-size: 3.5em;
// 	// letter-spacing: -1px;
// 	// line-height: @include 9;
// }

h2, .h2 {
	font-size: 2em;
}

h3, .h3 {
	font-size: 24px;
}

h4, .h4 {
	font-size: 20px;
	margin-bottom: 20px;
}

h5, .h5 {
	font-size: 14px;
}

h6, .h6 {
	font-size: 12px;
}

h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	font-family: $font-family-sans-serif;
	color: $brand-primary;
	font-weight: 700;
	font-style: normal;
	a { text-decoration: none; }
}

h1, h2, h3, h4, h5, h6 {
	a, a:hover { color: $brand-primary}
}

table {
	font-family: $font-family-sans-serif;
	th {
		font-family: $font-family-sans-serif;
		font-weight: 700;
	}
}

.well {
	font-family: $font-family-sans-serif;
}

.btn, input, select, textarea {
	font-family: $font-family-sans-serif;
}

.col-md-header {
	@media screen and (min-width: map-get($grid-breakpoints, "md")) { 	margin-top: 0; padding-top: 0; }
}

a {
	color: $link-blue;
	text-decoration: underline;
	&.btn {
		text-decoration: none;
	}
}

.small a {
	text-decoration: none;
}

.text-left {
	text-align: left!important;
}

.page-wrapper-inner {
	a {
		font-weight: bold;
	}
	// h1, h2 {
	// 	a {
	// 		font-weight: normal;
	// 	}
	// }
	ul, ol {
		ul {
			list-style-type: none;
			li:before {
				content:"—";
				position:relative;
				left:-5px;
			}
			li{ text-indent:-18px; }
			ul {
				list-style-type: square;
				li:before {
					content:normal;
					position:relative;
					left:auto;
				}
				li{ text-indent:0; }
			}
		}
	}
}
