/*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 *
 * CHANGELOG
 * CRAFT B5 - Upgrade bootstrap 3.3.2 to 5.0.2
 * CRAFT B5 - 09/20/21 - Include custom override styles 
 */

// scss-docs-start import-stack
// Configuration
@import "functions";
@import "variables";
/*CRAFT B5*/
@import "custom_variables.scss";
/*-CRAFT B5*/
@import "mixins";
@import "utilities";

// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "nav";
@import "navbar";
@import "card";
@import "accordion";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "alert";
@import "progress";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "offcanvas";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack

/*-CRAFT B5-*/
// Custom App

/*CRAFT B5*/
@import "custom_override_styles.scss";
/*-CRAFT B5*/
@import "custom_typography.scss";
@import "custom_forms.scss";
@import "custom_buttons.scss";
@import "custom_nav.scss";
@import "custom_social.scss";
@import "custom_footer.scss";
@import "custom_carousel.scss";
@import "custom_mobile_carousel.scss";
@import "custom_accordion.scss";
@import "custom_cards.scss";
@import "custom_dynamic-appear-buttons.scss";
@import "custom_pages.scss";
@import "custom_hubspot.scss";
@import "custom_coveo.scss";
//@import "custom_print.scss";

// 3rd party libs
@import "custom_data-tables.scss";
/*-CRAFT B5-*/