.cd-search .CoveoSearchbox .magic-box{
    border: none;
    border-radius: 0;
}

    .cd-search .CoveoSearchbox .magic-box .magic-box-input {
        height: 150px;
    }

.cd-search .CoveoSearchbox .magic-box .magic-box-input > input {
    color: rgb(51, 55, 56);
    font-size: 3.2rem;
    font-weight: 300;
    font-family: urw-din, Helvetica, Arial, sans-serif;
    height: 100%;
    padding: 0;
}

.cd-search .CoveoSearchbox .CoveoSearchButton, .page-wrapper .CoveoLogo {
    display: none;
}

#cd-search .magic-box .magic-box-clear {
    display: none;
}

#main-content .api-search-page.CoveoSearchInterface .coveo-search-section {
    max-width: 100%;
    padding: 0;
}

.CoveoSearchInterface{
    min-width: auto!important;

    .coveo-results-column{
        padding-left: 0!important;
        padding-top: 0!important;
    }
}

.coveo-list-layout.CoveoResult{
    padding: 5px 0;
}

.CoveoResultLink.nav-link{
    display: block;
    color: #333738;
    background-color: #e9ebea;
}

.CoveoResultLink.nav-link:visited{
    color: #333738;
}

.CoveoResultLink.nav-link:hover{
    text-decoration: none;
}

.api-search-page .CoveoSearchButton:hover .coveo-magnifier-circle-svg,
.api-search-page .CoveoSearchButton:hover svg g path {
    fill: #CD163F;
}

.api-search-page .coveo-results-column .coveo-results-header {
    float: right;
}

.api-search-page .CoveoBreadcrumb {
    margin-bottom: 10px;
}

.api-search-page .coveo-results-column .rss {
    text-indent: -9999px;
    float: right;
    margin-left: 5px;
    display: inline-block;
    height: 34px;
    width: 34px;
    background-image: url(../img/social_media/ico_social.png);
    -webkit-background-size: 34px 243px;
    -moz-background-size: 34px 243px;
    background-size: 34px 243px;
    background-position: 0 -139px;
    background-repeat: no-repeat;
}

.api-search-page .coveo-results-column .coveo-summary-section {
    display: block;
}
.api-search-page .facet-section-header {
    position: relative;
}

    .api-search-page .facet-section-header .CoveoSettings {
        position: absolute;
        top: 0;
        left: 78%;
        height: 30px;
        width: 30px;
    }
        .api-search-page .facet-section-header .CoveoSettings .coveo-settings-square {
            width: 3px;
            height: 3px;
        }

.api-search-page .CoveoPager {
    margin-left: 0;
}

.CoveoQuerySummary {
  margin-left: 10px;
}

@media screen and (min-width: 480px) {
  .api-search-page .coveo-pager-list-item:nth-of-type(1) {
      margin-left: 0;
  }
}

.api-search-page .coveo-list-layout.CoveoResult {
    border-bottom: thin solid #e9ebea;
    padding: 16px 0 16px 0;
}

.api-search-page .coveo-dynamic-facet-header {
    border-bottom: thin solid #e9ebea;
}

.api-search-page .CoveoResultLink, .api-search-page a.CoveoResultLink, .api-search-page .CoveoResult a.CoveoResultLink,
.api-search-page .CoveoResultLink, .api-search-page a.CoveoResultLink, .api-search-page .CoveoResult a.CoveoResultLink:hover,
.api-search-page .CoveoResultLink, .api-search-page a.CoveoResultLink, .api-search-page .CoveoResult a.CoveoResultLink:visited {
    color: #17457a !important;

}

@media screen and (max-width: map-get($grid-breakpoints, "lg")) {
    .cd-search .CoveoSearchbox .magic-box .magic-box-input {
        height: 50px;
    }

        .cd-search .CoveoSearchbox .magic-box .magic-box-input > input {
            font-size: 18px;
        }
}
